import * as React from "react";
import Badge from "@leafygreen-ui/badge";
import Box from "@leafygreen-ui/box";
import Icon from '@leafygreen-ui/icon';
import { MdCloudQueue } from "react-icons/md";
// import GlobalStorage from "../_Generics/GlobalStorage";
import Countdown from "../Countdown/Countdown";
import ElapsedTime from "../ElapsedTime/ElapsedTime";
import { FaExclamationTriangle } from 'react-icons/fa';
import Tooltip from '@leafygreen-ui/tooltip';
import "./HubColumn.css";

/**
 * Format hub data with default string data from key specified in column config
 * @param data hub data item
 */
export function StringRenderer(data: any) {
    let obj = '';
    if (typeof data.cellData !== "string") {
        for (let prop in data.cellData) {
            obj += data.cellData[prop] + ' ';
        }
    } else {
        obj = data.cellData;
    }
    return (
        <div className={data.className}>{obj}</div>
    )
}

/**
 * Render NTSE badge given hub item
 * @param data hub data item
 */
export function renderNTSEs(data: any) {
    if (data.ntses && data.ntses.length > 0) {
        return <Badge
            className="ntse-badge badge-green"
            variant="green"
            title={data
                .ntses
                .join(", ")}>NTSE</Badge>
    }
    return "";
}

/**
 * Render Proactive badge given hub item
 * @param data hub data item
 */
 export function renderProactive(data: any) {
    if (data.isProactive) {
        return <Badge
            className="proactive-badge badge-green"
            variant="green"
            title="This case was opened on behalf of the customer">PROACTIVE</Badge>
    }

    return "";
}

/**
 * Render AutoClose warningsign badge given hub item
 * @param data hub data item
 */
 export function renderAutoCloseWarningSign(data: any) {
    const title = "Majority work type and Root cause need to be set";

    if (data.autoClose) {
        return <div title={title} className="warning-autoclose" >
            <FaExclamationTriangle size="20" />
        </div>
    }

    return "";
}

/**
 * Check if the property reviewers exist so we can conclude this is a review case and if SLA then show the label
 * Render SLA badge given hub item
 * @param data hub data item
 */
 export function renderSLA(data: any) {

    if (data.isSLA && data.hasOwnProperty('reviewers')) {
        return <Badge
            className="sla-badge badge-red"
            variant="red"
            title="SLA">SLA</Badge>
    }

    return "";
}

/**
 * Check if item is salesforce.
 * Really only checking that the id starts with 0
 * @param item hub data item
 */
export function isItemSalesforce(item: any) {
    return item
        .id
        .indexOf("0") === 0;
}

/**
 * Render escalation badge based on hub data item
 * @param item hub data item
 */
export function renderEscalated(item: any) {
    if (item.escalated && item.escalated === true) {
        return <Badge className="case-component fts-badge badge-red" variant="red" title="Escalated">ESC</Badge>
    }
    return "";
}

/**
 * Render stickies badge based on hub data item
 * @param item hub data item
 */
export function renderStickies(item: any) {
    if (item.escalated && item.escalated === true && item.stickies && Object.keys(item.stickies).length !== 0) {
        return <Badge className="case-component stickies-badge badge-green" variant="green" title="Sticky">Sticky</Badge>
    }
    return "";
}

/**
 * Render link with correct target and rel attributes
 * @param {string} href
 * @param {string} title
 * @param {string} content
 */
export function renderLink(href: string, title: string, content: string) {
    return <a 
        className="styleAnchor"
        href={href} title={title} 
        target="_blank" 
        rel="noopener noreferrer">{content}</a>;
}

/**
 * Render all components based on hub data item
 * @param item hub data item
 */
export function renderComponents(item: any) {
    const triageComps = (item.triageComps && item.triageComps.length > 0)
        ? item.triageComps
        : item.triageComponents;
    const comps = (item.comps && item.comps.length > 0)
        ? item.comps
        : item.components;
    if (comps && comps.length > 0) {
        return (comps.map((comp: any, idx: number) => (
            <Badge className="case-component" variant="green" key={idx}>{comp}</Badge>
        )));
    } else if (triageComps && triageComps.length > 0) {
        return (triageComps.map((comp: any, idx: number) => (
            <Badge className="case-component badge-lightgray" variant="lightgray" key={idx}>{comp}</Badge>
        )));
    }
    return "";
}

/**
 * Convert user name from first.last to First Last
 * @param {string} s expects first.last
 */
export function getNameFromUsername(s: string) {
    let splitStr = s.split('.');
    return splitStr[0]
        .charAt(0)
        .toUpperCase() + splitStr[0].substring(1) + ' ' + splitStr[1]
            .charAt(0)
            .toUpperCase() + splitStr[1].substring(1);
}




/**
 * Render link to Project from react-base-table column
 * @param data react-base-table column
 */
export function Account(data: any) {
    const item: any = data.rowData;

    if(item.source && item.source.includes('mongodbgov'))
        return '( redacted )';

    let project = (item.cloudProject && item.cloudProject.length > 0) ? item.cloudProject : item.sf_project_id;
    if (project && project.length > 0) {
        return renderLink("https://mongodb.my.salesforce.com/" + project, "Open Salesforce project of " + item.company, item.company);
    }
    return renderLink("https://corp.mongodb.com/clienthub/jira/view?name=" + item.company, "Open Jira project of " + item.company, item.company);
}

/**
 * Render cloud icon from react-base-table column
 * @param data react-base-table column
 */
export function Cloud(data: any) {
    const item: any = data.rowData;
    return (item.cloudProject && item.cloudProject.length > 0)
        ? <span className="cloud-badge"><MdCloudQueue /></span>
        : '';
}

/**
 * Render follow up badge from react-base-table column
 * @param data react-base-table column
 */
export function FollowUp(data: any) {
    const item: any = data.rowData;
    return  <Badge
        className={"sla-badge badge-" + item.CALC_slaColor}
        variant={item.CALC_slaColor}>
        {item.CALC_slaTimeStr}
    </Badge>
}

/**
 * Render link to salesforce or jira from react-base-table column
 * @param data react-base-table column
 */
export function renderId(data: any) {
    const item: any = data.rowData;
    if (item.sfdc) {
        if(item.source && item.source.includes('mongodbgov'))
            return renderLink("https://mongodbgov.my.salesforce.com/" + item.Id, "Open Salesforce case #" + item.Id, item.Id)
        else
           return renderLink("https://mongodb.my.salesforce.com/" + item.Id, "Open Salesforce case #" + item.Id, item.id);
    }
    if (item.jiraCaseId) {
        return renderLink("https://jira.mongodb.org/browse/" + item.Id, "Open Jira ticket #" + item.Id, item.Id);
    }
    return <span></span>;
}

/**
 * Return FTS or SLA from react-base-table column
 * @param data react-base-table column
 */
export function Label(data: any) {
    const item: any = data.rowData;

    let label = '';
    if (item.isFTS === 1) {
        label = "FTS";
    } else if (item.isSLA === 1) {
        label = "SLA";
    }
    return label;
}

/**
 * Return the last comment created by name from react-base-table column.
 * @param data react-base-table column
 */
export function LastComment(data: any) {
    const item: any = data.rowData;

    if(item.source && item.source.includes('mongodbgov')) {
        return '( redacted )';
    } else {
        return item.lastPublicCommentUser && item.lastPublicCommentUser.CreatedByName;
    }
}

/**
 * Return the lookers from a react-base-table column
 * @param data react-base-table column
 */
export function Lookers(data: any) {
    const item: any = data.rowData;
    //TODO: not sure how the data is returned yet
    return item.lookers && item
        .lookers
        .map((r: string) => getNameFromUsername(r))
        .join(', ');
}

/**
 * Return the reviewers in a comma separated list from a react-base-table column
 * @param data react-base-table column
 */
export function Reviewers(data: any) {
    const item: any = data.rowData;
    //TODO: not sure how the data is returned yet
    return item.reviewers && item
        .reviewers
        .map((r: string) => getNameFromUsername(r))
        .join(', ');
}

/**
 * Return the reviewers in a comma separated list from a react-base-table column
 * @param data react-base-table column
 */
export function Severity(data: any) {
    const item: any = data.rowData;
    return (isItemSalesforce(item)
        ? "S"
        : "P") + item.priority;
}

export function BaseMainScore(data: any) {
    let base_score = parseFloat(data.rowData.base_score || 0);
    let orig_score = parseFloat(data.rowData.orig_score || 0);
    let remaining_minutes = parseInt(data.rowData.remaining_minutes || 0);
    let max_remaining_score = parseFloat(data.rowData.max_remaining_score || 0);
    let decay_text = data.rowData.decay_text || "";

    if (data.rowData.base_score === undefined) {
        return <></>
    }

    if ((orig_score !== base_score) ) {
        return (<Box>
            <div style={{width: "50px", display: "inline-block"}}>
            <span className={'load-score'}>{orig_score.toFixed(1)}</span>
            <span className={'base-load-score'}>/{base_score.toFixed(1)}</span>
            </div>
            <Tooltip trigger={
                <span style={{top: "3px"}}>
                    <Icon className={'load-score-info-2'} size="small" glyph="InfoWithCircle" fill="#89979B" />
                </span>}
            triggerEvent="hover"
            darkMode={true}
            >
            {(remaining_minutes/60).toFixed(1)}
            hrs until score reaches {max_remaining_score}.<br/> Decays fully in {decay_text}
            </Tooltip>
        </Box>)
    }
    else {
        return <Box>
        <div className={'load-score'}>{orig_score.toFixed(1)}</div>
        </Box>
    }


}

export function ScoreTone(data: any) {
    // red Light 2: #F9D3C5
    // Red Base:    #CF4A22
    // red Dark 1:  #B1371F
    // red Dark 2:  #8F221B
    // Red Dark 3:  #570B08
    type ScoreColor = { [index: number]:
                            {background: string, text: string} };
    const score_color: ScoreColor = {
        1: {"background": "#F9D3C5", "text": "#3D4F58"},
        2: {"background": "#CF4A22", "text": "#FCEBE2"},
        3: {"background": "#B1371F", "text": "#FCEBE2"},
        4: {"background": "#8F221B", "text": "#FCEBE2"},
        5: {"background": "#570B08", "text": "#FCEBE2"},
    }
    let score = parseFloat(data.rowData.score);
    let score_range = Math.floor(score / 20);
    score_range = score_range < 1 ? 1 : score_range;
    score_range = score_range > 5 ? 5 : score_range;
    let bg_color = score_color[score_range].background;
    let txt_color = score_color[score_range].text;
    let new_style = {backgroundColor: bg_color,
                     width: '100px',
                     height: '40px'};
    let text_style = {paddingTop: '10px',
                      paddingLeft: '8px',
                      color: txt_color
                    };
    return <Box style={new_style}>
        <div style={text_style}>{score.toFixed(1)}</div>
    </Box>
}

export function SeverityBadge(data: any) {
    const item: any = data.rowData;
    type PrioColor = { [index: string]: string }
    const prio_color: PrioColor = {
        'S1': "red",
        'S2': "yellow",
        'S3': "green",
        'S4': "green",
        };

    if (!item.prio && !item.sev) { return <></> }
    let item_str = String(item.prio || item.sev);
    let sev: any = item_str.toUpperCase().replace('P', 'S');
    return <Badge
        className={"sla-badge"} variant={prio_color[sev] as any}>
        {item_str}
    </Badge>
    /* return (isItemSalesforce(item)
        ? "S"
        : "P") + item.priority;*/
}

/**
 * Return the SLA badge from a react-base-table column
 * @param data react-base-table column
 */
export function SLA(data: any) {
    const item: any = data.rowData;
    return <Badge
        className={"sla-badge badge-" + item.CALC_slaColor}
        variant={item.CALC_slaColor}>
        {item.CALC_slaTimeStr}
    </Badge>
}

/**
 * Return the FTS Next Action badge from a react-base-table column
 * @param data react-base-table column
 */
export function FTSNextAction(data: any) {
    const item: any = data.rowData;
    return item.ftsNextAction && item.ftsNextAction !== '' ? <Badge
        className={"sla-badge badge-" + item.CALC_nextActionColor}
        variant={item.CALC_nextActionColor.split('-')[0]}>
        {item.ftsNextAction}
    </Badge> : ''
}

/*
 * Return the SLA badge from a react-base-table column
 * @param data react-base-table column
 */
export function CountDownSeconds(data: any) {
    const item: any = data.rowData;

    // if(!item.timestamp) {
    //   return null;
    // } else if (item.CALC_remainingSeconds < 0) {
    //     return <Badge className={"sla-badge badge-red"} variant={"red"}>
    //     MISSED
    //     </Badge>
    //

    // if (item.CALC_remainingSeconds < 0) {
    //     return <Badge className={"sla-badge badge-red"} variant={"red"}> <div>oh no!</div>
    //     MISSED
    //     </Badge>
    //   } else {
        return <Countdown
            /* timestamp={item.timestamp} */
            isSLA={item.isSLA}
            isFollowUp={item.CALC_slaTime.isFollowUp}
            // days={item.CALC_slaTime.days}
            // hours={item.CALC_slaTime.hours}
            // minutes={item.CALC_slaTime.minutes}
            // seconds={item.CALC_slaTime.seconds}
            //expiresAt={item.slaExpiresAt ? item.slaExpiresAt : item.followUpExpireAt}
            expiresAt={item.slaExpiresAt}
            color={item.CALC_slaColor}>
        </Countdown>
  // }

    /* display={item.CALC_slaTime.display*/
}

export function FollowUpCountDown(data: any) {
    const item: any = data.rowData;

    // if (item.CALC_remainingSeconds < 0) {
    //     return <Badge className={"sla-badge badge-red"} variant={"red"}>
    //     MISSED
    //     </Badge>
    //   } else {
        return <Countdown
            isSLA={item.isSLA}
            isFollowUp={item.CALC_slaTime.isFollowUp}
            expiresAt={item.followUpExpireAt ?? item.slaExpiresAt}
            color={item.CALC_slaColor}>
        </Countdown>
  // }
}

export function StatusChangeTime(data: any) {
    const item: any = data.rowData;
    // if(!item.timestamp) {
    //     return null;
    // } else {
    let elapsed_raw = parseInt(data.cellData || '0');
    let e_seconds = elapsed_raw % 60;
    elapsed_raw = Math.floor(elapsed_raw / 60);
    let e_minutes = elapsed_raw % 60;
    elapsed_raw = Math.floor(elapsed_raw / 60);
    let e_hours = (elapsed_raw % 60) % 24;
    elapsed_raw = Math.floor(elapsed_raw / 24);
    let e_days = elapsed_raw % 24;
    if (data.cellData === undefined) { return <></> }
    return <ElapsedTime
        timestamp={item.timestamp}
        days={e_days}
        hours={e_hours}
        minutes={e_minutes}
        seconds={e_seconds}>
    </ElapsedTime>
    //}
}


export function WaitingElapsedTime(data: any) {
    const item: any = data.rowData;
    // if(!item.timestamp) {
    //     return null;
    // } else {
    return <ElapsedTime
        timestamp={item.timestamp}
        days={item.CALC_waitingTimeObj.days}
        hours={item.CALC_waitingTimeObj.hours}
        minutes={item.CALC_waitingTimeObj.minutes}
        seconds={item.CALC_waitingTimeObj.seconds}>
    </ElapsedTime>
    //}
}

/**
 * Render the waiting score column
 * @param data react-base-table column
 */
export function WaitingScore(data: any) {
    const item: any = data.rowData;
    let waitingMinutes = item.CALC_waitingSeconds / 60;
    if (item.CALC_initialResponseSLAHours !== 0) {
        return Math.round(waitingMinutes / item.CALC_initialResponseSLAHours);
    } else {
        return 0;
    }

}

/**
 * Render the TagsAndTitle column from a react-base-table column
 * @param data react-base-table column
 */
export function TagsTitle(data: any) {

    const myCasesView = data.container.props.rowKey === 'USERWAITING';

    const item: any = data.rowData;
    return <span>
        {item.desc && item.desc}
        {myCasesView && renderAutoCloseWarningSign(item)}
        {item.description && item.description}
        {renderEscalated(item)}
        {renderNTSEs(item)}
        {renderProactive(item)}
        {renderSLA(item)}
        {renderComponents(item)}
        {/* TSTOOLS-2324 {renderStickies(item)} */}
    </span>
}

/**
 * Render the TCWatchout score column
 * @param data react-base-table column
 */
export function TCWScore(data: any) {
    const item: any = data.rowData;
    return item.tcwscore;
}
/**
 * Render the TCWatchout issye typlogy SLA/FTS/UNA column
 * @param data react-base-table column
 */
export function TCWTypology(data: any) {
    const item: any = data.rowData;
    return item.tcwtypology;
}
/**
 * Render the TCWatchout TagsAndTitle column from a react-base-table column
 * @param data react-base-table column
 */
export function TCWTagsTitle(data: any) {
    const item: any = data.rowData;
    return <span>
        {renderEscalated(item)}
        {renderNTSEs(item)}
        {renderSLA(item)}
        {renderComponents(item)}
        {renderProactive(item)}
        {/* TSTOOLS-2324 {renderStickies(item)} */}
        {" " + item.desc || " " + item.description}
    </span>
}
/**
 * Loose list for renders, this maps functions to the data in get columns
 */
export const Renderers = {
    string: StringRenderer,
    Project: Account,
    cloud: Cloud,
    followup: FollowUp,
    id: renderId,
    label: Label,
    lastcomment: LastComment,
    lookers: Lookers,
    reviewers: Reviewers,
    severity: Severity,
    sev: SeverityBadge,
    prio: SeverityBadge,
    score: ScoreTone,
    base_score: BaseMainScore,
    sla: SLA,
    sla_countdown: CountDownSeconds,
    followup_countdown : FollowUpCountDown,
    waiting_elapsedTime: WaitingElapsedTime,
    status_changeTime: StatusChangeTime,
    waiting_score: WaitingScore,
    tagstitle: TagsTitle,
    ftsNextAction: FTSNextAction,
    tcwscore: TCWScore,
    tcwtypology: TCWTypology,
    tcwtagstitle: TCWTagsTitle
}

export function ColumnConfig() {
    /** This function returns the column definitions to be used by using index of a column type.
     * Ie.
     * ["id", "Project"]
    */
    function getColumns(): { [key: string]: any } {
        return {
            sev: {
                title: "Severity",
                key: "sev",
                dataKey: "sev",
                format: "sev",
                width: 104,
                sortable: true
            },
            prio: {
                title: "Priority",
                key: "prio",
                dataKey: "prio",
                format: "prio",
                width: 104,
                sortable: true
            },
            id: {
                title: "Case",
                key: "id",
                dataKey: "id",
                format: "id",
                width: 105,
                sortable: true,
                minWidth: 105,
            },
            status: {
                title: "Status",
                key: "status",
                dataKey: "status",
                width: 168,
                sortable: false
            },
            status_change: {
                title: "Status Change",
                key: "status_change",
                dataKey: "status_change",
                format: "status_changeTime",
                width: 168,
                sortable: true,
                flexGrow: 1,
            },
            base_score: {
                title: "Base Score",
                key: "base_score",
                width: 100,
                format: "base_score",
            },
            escalated: {
                title: "Escalated",
                key: "escalated",
                dataKey: "escalated",
                width: 101,
                sortable: true,
            },
            fts: {
                title: "FTS",
                key: "fts",
                dataKey: "fts",
                width: 101,
            },
            score: {
                title: "Score",
                key: "score",
                width: 100,
                format: "score",
                style: {paddingRight: "0px"},
                sortable: true,
            },
        };
    }

    const columns = getColumns();

    /**
     * Build the sort object from our easy configson the columns.
     * @param sort
     */
    function getSort(sort: any[]) {
        let sortOrder: any = {
            orderOfSorts: [],
            sortState: {}
        }
        sort.forEach((s) => {
            for (let prop in s) {


                sortOrder.orderOfSorts.push(columns[prop].key);
                sortOrder.sortState[columns[prop].key] = s[prop];
            }

        })
        return sortOrder;
    }
    /** Here we are creating the table definitions with all the columns.
     * Creating them with a string array allowed us to seperate out each
     * column definition for easier management.
     */
    return {
        "SFDC": {
            title: "Salesforce Cases",
            columns: [
                "sev",
                "id",
                "status",
                "status_change",
                "base_score",
                "escalated",
                "fts",
                "score",
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sev": "asc" }])
        },
        "HELP": {
            title: "Help Tickets",
            columns: [
                "prio",
                "id",
                "status",
                "score",
            ].map((col) => columns[col]),
            defaultSort: getSort([{ "sev": "asc" }])
        },
    }
}

