import * as React from "react";
import BaseTable, { Column, SortOrder } from "react-base-table";
import "react-base-table/styles.css";
import "./TableWrapper.css";

import * as TableConfig from "../_Configs/HubColumns";
import alphanumericCollator from "../_Generics/alphanumericCollator";
import { StitchAppClient } from "mongodb-stitch-browser-sdk";
import GlobalStorage from "../_Generics/GlobalStorage";
import BaseTableResizer from "./ResizingTableWrapper";
import { FaBicycle } from "react-icons/fa";

type Props = {
  data: any;
  client: StitchAppClient | undefined;
};
type State = {
  tableConfig: any;
  components: any;
  sortState: any;
  orderOfSorts: any;

  showEmptyUserTables: boolean;
  selectedItem: any;

  selectedTicketTypeFilter: string;
  searchString: string;
};

export class TableWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const renderers: { [key: string]: any } = TableConfig.Renderers;
    const tableConfig: any = TableConfig.ColumnConfig();

    let orderOfSorts: any = {};
    let sortState: any = {};
    for (let prop in tableConfig) {
      orderOfSorts[prop] = tableConfig[prop].defaultSort.orderOfSorts;
      sortState[prop] = tableConfig[prop].defaultSort.sortState;
    }

    const Cell = (cellProps: any) => {
      const format = cellProps.column.format || 'string'
      const renderer = renderers[format] || renderers.string;

      return renderer(cellProps);
    };

    this.state = {
      tableConfig: tableConfig,
      components: {
        TableCell: Cell,
      },
      orderOfSorts: orderOfSorts,
      sortState: sortState,
      showEmptyUserTables: true,
      selectedItem: null,

      selectedTicketTypeFilter: "all",
      searchString: "",
    };

    // What are we doing here?
    // We are storing the keyup key down to check if ctrl has been pressed while doing sorting
    // This is slightly buggy (as the last time you are doing a multi search, will bleed into doing a single click)
    GlobalStorage.wasLastKeyUpCtrl = null;
    GlobalStorage.wasLastKeyDownCtrl = null;
    window.onkeyup = function (e: any) {
      GlobalStorage.wasLastKeyUpCtrl = e.key === "Control";
    };
    window.onkeydown = function (e: any) {
      GlobalStorage.wasLastKeyDownCtrl = e.ctrlKey;
    };

    GlobalStorage.stitchClient = props.client;

  }

  onColumnSort = (vars: any, view: string) => {
    //console.log(GlobalStorage.wasLastKeyDownCtrl, GlobalStorage.wasLastKeyUpCtrl);
    const isMultiSort =
      GlobalStorage.wasLastKeyDownCtrl && !GlobalStorage.wasLastKeyUpCtrl;
    GlobalStorage.wasLastKeyUpCtrl = null;
    GlobalStorage.wasLastKeyDownCtrl = null;
    GlobalStorage.didGetClick = false;

    const { sortState, orderOfSorts } = this.state;
    const { key, order } = vars;

    //TODO EXPAND THIS TO BE ABLE TO SORT ORDER BY MULTIPLE
    let viewSortState = sortState[view] ? sortState[view] : {};
    let newViewSort: any;
    let newViewOrderOfSorts = orderOfSorts[view] ? orderOfSorts[view] : [];
    if (isMultiSort) {
      let newViewState = viewSortState[key] === SortOrder.DESC ? null : order;
      // clear the sort state if the previous order is desc
      newViewSort = {
        ...viewSortState,
        [key]: newViewState,
      };
      if (orderOfSorts[view] && orderOfSorts[view].includes(key)) {
        //do nothing
      } else {
        newViewOrderOfSorts.push(key + "");
      }
    } else {
      newViewSort = {
        [key]: viewSortState[key] === SortOrder.DESC ? null : order,
      };
      newViewOrderOfSorts = [key + ""];
    }
    //console.log(newViewSort);
    this.setState({
      orderOfSorts: {
        [view]: newViewOrderOfSorts,
      },
      sortState: {
        ...sortState,
        [view]: newViewSort,
      },
    });
  };

  doSort(data: any) {
    const { orderOfSorts, sortState, tableConfig } = this.state;
    //console.log(sortState);
    //console.log(orderOfSorts);
    const sortData = data;

    // for each view sort
    for (let prop in sortState) {
      // if view sort applies to a view in the data and it has data in it
      if (sortData && sortData[prop] && sortData[prop].length > 1) {
        const viewConfig = tableConfig[prop];
        // for each column that is being sorted

        sortData[prop] = sortData[prop].sort((a: any, b: any) => {
          let result = 0;
          if (orderOfSorts[prop] && orderOfSorts[prop].length) {
            for (let i = 0; i < orderOfSorts[prop].length; i++) {
              const sortKey = orderOfSorts[prop][i];
              //let sortProp = sortState[prop][sortKey];

              const column = viewConfig.columns.find(
                (v: any) => v.key === sortKey
              );
              const aVal =
                column && column.sortAccessor
                  ? column.sortAccessor(a)
                  : a[sortKey];
              const bVal =
                column && column.sortAccessor
                  ? column.sortAccessor(b)
                  : b[sortKey];
              result = alphanumericCollator(
                sortState[prop][sortKey] !== SortOrder.ASC
              )(aVal, bVal);
              if (result !== 0) {
                return result;
              }
            }
          }
          return result;
        });
      }
    }
    return sortData;
  }

  swapShowEmptyUserTables = (ev: any) => {
    this.setState({
      showEmptyUserTables: !this.state.showEmptyUserTables,
    });
  };

  updateFilterSearch = (ev: any) => {
    const newTypeFilter = ev.target.value.toLowerCase();
    if (this.state.selectedTicketTypeFilter !== newTypeFilter) {
      this.setState({ selectedTicketTypeFilter: newTypeFilter });
    }
  };
/*
  showDetailsModal = (itemId: any) => {
    // const { rowData } = data;
    const { data, views } = this.props;
    let item: any;
    views.forEach((v) => {
      let found = data[v].find((i: any) => i.id === itemId);
      if (found) {
        item = found;
      }
    });
    this.setState({
      selectedItem: item,
    });
  };
*/
  render() {
    const emptyRender: React.FunctionComponent = () => {
      return (
        <div className="table-empty-container well text-center">
          <div className="empty-table-message">
            <FaBicycle />
            <div>Neat! No items to show.</div>
          </div>
        </div>
      );
    };

    const { data } = this.props;
    const {
      components,
      tableConfig
    } = this.state;
    let views: any = ['SFDC', 'HELP'];
    
    const filteredData = this.doSort(data);
    console.log(filteredData);
    console.log(views);
    return (
      <React.Fragment>
        <div className="table-filters">
          <div className="table-wrapper-search">
            {/* <div className="dropdown-wrapper">
                            <ReactDropdownSelect placeholder="Limit tables to..." onChange={this.selectedTableLimitChange}  className="select" options={pageOptions}/>
                        </div> */}
          </div>
        </div>
        
        <div className="tables-wrapper">
          {filteredData &&
            views.map((v: string, idx: number) => {
              console.log(v + ' __'+ idx);

              const config = tableConfig[v];
              const tableResizer = v === "ACTS" || v === "WAIT";
              console.log("Rendering TableWrapper");
              //console.log(this.state.sortState[v]);
              return (
                <div className="table-wrapper" key={"table-" + idx}>
                  <div className="table-wrapper-header">
                    <div className="table-wrapper-title">
                      {config.title}
                    </div>

                    {!tableResizer && (
                      <BaseTable
                        rowKey={v}
                        className="table non-scrolling-table"
                        data={filteredData[v] ? filteredData[v] : []} //safe filter
                        width={1837}
                        rowHeight={40}
                        headerHeight={40}
                        maxHeight={1250}
                        components={components}
                        rowClassName=""
                        emptyRenderer={emptyRender}
                        sortState={
                          this.state.sortState[v] ? this.state.sortState[v] : {}
                        }
                        onColumnSort={(data) => this.onColumnSort(data, v)}
                      >
                        {config.columns.map((c: any) => (
                          <Column {...c} />
                        ))}
                      </BaseTable>
                    )}
                    {tableResizer && (
                      <BaseTableResizer
                        config={config}
                        data={filteredData[v] ? filteredData[v] : []} //safe filter
                        components={components}
                        sortState={
                          this.state.sortState[v] ? this.state.sortState[v] : {}
                        }
                        onColumnSort={(data) => this.onColumnSort(data, v)}
                      ></BaseTableResizer>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}
