import React, { Component } from "react";
import Icon from '@leafygreen-ui/icon';
import Grid from '@material-ui/core/Grid';
import { Label } from "reactstrap";
import { app } from "../../stitch/app";
import { H2 } from "@leafygreen-ui/typography";
import "./ModelComp.css";
import Select from "react-select";

class Model extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      userEmail: null,
      rr_minutes_static: [{
        value: 15,
        label: 15
    }],
    rr_excluded_minutes_static: [{
        value: 30,
        label: 30
    }],

    current: {
        classifier_confidence: null,
        severity_modifier: null,
        skill_level_bonus: null,
        skill_match_bonus: null,
        ntse_bonus: null,
        atlas_penalty: null,
        tse_coefficient: null,
        tse_load_exponent: null,
        max_case_weight: null,
        reference_case_load: null,
        assignment_bonus: null,
        assignment_penalty: null },

    current_SLA: {
        sla_15: {
            threshold: null,
            timeout: null,
            max_attempts: null,
        },
        sla_30: {
            threshold: null,
            timeout: null,
            max_attempts: null,
        },
        sla_60: {
            threshold: null,
            timeout: null,
            max_attempts: null,
        },
        sla_120: {
            threshold: null,
            timeout: null,
            max_attempts: null,
        },
        sla_240: {
            threshold: null,
            timeout: null,
            max_attempts: null,
        },
        sla_360: {
            threshold: null,
            timeout: null,
            max_attempts: null,
        }
    },
      
    model_name: "Model Name 1",
    classifier_confidence: null,
    severity_modifier: null,
    skill_level_bonus: null,
    skill_match_bonus: null,
    ntse_bonus: null,
    atlas_penalty: null,
    tse_coefficient: null,
    tse_load_exponent: null,
    max_case_weight: null,
    reference_case_load: null,
    assignment_bonus: null,
    assignment_penalty: null,
    rr_bonus: null,
    rr_penalty: null,
    rr_minutes: null,
    rr_threshold: null,
    rr_excluded_total_minutes: null,
    };
  }

  componentDidMount() {
    app.callFunction("fetchActiveModel").then((res) => {
        this.setState(prevState => ({
        userName: res.user_info.name,
        userEmail: res.user_info.email,
        current: {
          ...prevState.current,
          classifier_confidence: res.model.weights.classifier_confidence,
          severity_modifier: res.model.weights.severity_modifier,
          skill_level_bonus: res.model.weights.skill_level_bonus,
          skill_match_bonus: res.model.weights.skill_match_bonus,
          ntse_bonus: res.model.weights.ntse_bonus,
          atlas_penalty: res.model.weights.atlas_penalty,
          tse_coefficient: res.model.weights.tse_coeff,
          tse_load_exponent: res.model.weights.tse_load_exponent,
          max_case_weight: res.model.weights.max_case_weight,
          reference_case_load: res.model.weights.reference_case_load,
          assignment_bonus: res.model.weights.assignment_bonus,
          assignment_penalty: res.model.weights.assignment_penalty,
          rr_bonus: res.model.weights.rr_bonus,
          rr_penalty: res.model.weights.rr_penalty,
          rr_minutes: res.model.weights.rr_total_minutes[0],
          rr_threshold: res.model.weights.rr_threshold,
          rr_excluded_total_minutes: res.model.weights.rr_excluded_total_minutes[0]
        },
        current_SLA: {
            ...prevState.current_SLA,
            sla_15: {
                ...prevState.current_SLA.sla_15,
                threshold: res.model.assignments.sla_15.threshold,
                timeout: res.model.assignments.sla_15.timeout,
                max_attempts: res.model.assignments.sla_15.max_attempts,
            },
            sla_30: {
                ...prevState.current_SLA.sla_30,
                threshold: res.model.assignments.sla_30.threshold,
                timeout: res.model.assignments.sla_30.timeout,
                max_attempts: res.model.assignments.sla_30.max_attempts,
            },
            sla_60: {
                ...prevState.current_SLA.sla_60,
                threshold: res.model.assignments.sla_60.threshold,
                timeout: res.model.assignments.sla_60.timeout,
                max_attempts: res.model.assignments.sla_60.max_attempts,
            },
            sla_120: {
                ...prevState.current_SLA.sla_120,
                threshold: res.model.assignments.sla_120.threshold,
                timeout: res.model.assignments.sla_120.timeout,
                max_attempts: res.model.assignments.sla_120.max_attempts,
            },
            sla_240: {
                ...prevState.current_SLA.sla_240,
                threshold: res.model.assignments.sla_240.threshold,
                timeout: res.model.assignments.sla_240.timeout,
                max_attempts: res.model.assignments.sla_240.max_attempts,
            },
            sla_360: {
                ...prevState.current_SLA.sla_360,
                threshold: res.model.assignments.sla_360.threshold,
                timeout: res.model.assignments.sla_360.timeout,
                max_attempts: res.model.assignments.sla_360.max_attempts,
            }
        }
        }));
      });
  }

  resetStyle = (id) => {
    document.getElementById(id).style.borderColor = null;
  }

  resetInputs = () => {
    document.getElementById("create").reset();
  }

  sendAlert = (field, value) => {
    alert(`Please check ${field} value. ${value} is not acceptable.`);
  }

  checkValidation = () => {
    if(this.state.model_name === "Model Name 1") {
      document.getElementById('new_model_name').style.borderColor = "red";
      this.sendAlert("Model Name", this.state.model_name);
    } else if (this.state.rr_minutes === null) {
        this.sendAlert("RR Minutes", "Empty input");
    } else if(this.state.rr_excluded_total_minutes === null) {
        this.sendAlert("RR Excluded Minutes", "Empty input");
    } else {
      return true;
    }
  }

  handleSubmit = async event => {
    const validate = this.checkValidation();
    event.preventDefault();
    if (validate) {
      const model_data = this.state;
      await app.callFunction("createNewModel", [model_data]).then(res => {
          console.log("Success!!");
      });
      alert("Model has been created");
      this.resetInputs();
      console.log("Success");
    }
  }

  validate = (id, value) => {
    if(!isNaN(value)) {
        this.setState({[id]: value});
    }
    }

  render() {
    const message = {
        classifier_confidence: "Classifer Confidence",
        severity_modifier: "Severity Modifier",
        skill_level_bonus: "Skills Level Bonus",
        skill_match_bonus: "Skill Match Bonus",
        ntse_bonus: "NTSE Bonus",
        atlas_penalty: "Atlas Penalty",
        tse_coeff: "TSE Co-effiencient",
        tse_load_exponent: "TSE Load Exponent",
        assignment_bonus: "Assignment Bonus",
        assignment_penalty: "Assignment Penalty",
        rr_bonus: "Rapid Responder Bonus",
        rr_penalty: "Rapid Responder Penalty",
        rr_minutes: "Rapid Responder Minutes",
        rr_threshold: "Rapid Responder Threshold",
        rr_excluded_total_minutes: "Rapid Responder Excluded for 'x' number of minutes",
        max_case_weight: "Maximum Case Weight",
        reference_case_load: "Reference Case Load",
        threshold: "SLA Remaining",
        timeout: "Timeout Slack Message after 'x' minutes",
        max_attempts: "Number of attempts for the case"
    };
    
    return (
      <form id="create" onSubmit={this.handleSubmit}>
        <H2 className="tab-heading">Create New Model</H2>
        <div className="model_div_left">
            <div className="create_model_info">
            <div className="create_model_name">
                <input id="new_model_name" className="create_model" placeholder="Model Name 1" onChange={(e)=> {this.setState({model_name: e.target.value})}} required/>
            </div>
            <br></br>
            <Label className="creator_name">
                Creator: {this.state.userName}
            </Label>
                <input className="save_button" type="submit" value="Save"/>
            </div>
            <div className="create_model_paramters">
            <div className="parameters_set">
                    <div>
                    <Label className="group_label">Ticket Information</Label>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Classifier Confidence</Label><Icon glyph="InfoWithCircle" size="small" title={message.classifier_confidence} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="classifier_confidence" onFocus={ (e) => this.resetStyle(e.target.id) } value={this.state.classifier_confidence}
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required>
                                </input><br></br>
                                <Label className="default_values">Current: {this.state.current.classifier_confidence}</Label><br></br>
                            </Grid>
                            <Grid item xs={5}>
                                <Label className="ticket_parameters">Severity Modifier</Label><Icon glyph="InfoWithCircle" size="small" title={message.severity_modifier} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="severity_modifier" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.severity_modifier}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Label className="group_label">TSE Information</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Skill Level Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.skill_level_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="skill_level_bonus" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.skill_level_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Skills Match Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.skill_match_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="skill_match_bonus" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.skill_match_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">NTSE Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.ntse_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="ntse_bonus" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.ntse_bonus}</Label><br></br>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Atlas Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.atlas_penalty} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="atlas_penalty" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.atlas_penalty}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">TSE Coefficient</Label><Icon glyph="InfoWithCircle" size="small" title={message.tse_coeff} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="tse_coefficient" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.tse_coefficient}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">TSE Load Exponent</Label><Icon glyph="InfoWithCircle" size="small" title={message.tse_load_exponent} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="tse_load_exponent" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.tse_load_exponent}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                    <Label className="group_label">Case Assignment</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Assignment Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.assignment_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="assignment_bonus" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.assignment_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Assignment Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.assignment_penalty} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="assignment_penalty" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.assignment_penalty}</Label><br></br>
                            </Grid>
                        </Grid>
                        </div>
                        <div>
                        <Label className="group_label">Rapid Responder</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="rr_bonus" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_penalty} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="rr_penalty" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_penalty}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">RR Total Minutes</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_minutes} className="tooltip_info_icon"/><br></br>
                                <Select
                                    className="input_fields_select"
                                    id="rr_minutes"
                                    placeholder="RR Minutes"
                                    onChange={(e) => {this.setState({rr_minutes: e.value})}}
                                    options={this.state.rr_minutes_static}
                                    required
                                /><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_minutes}</Label><br></br>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Threshold</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_threshold} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="rr_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_threshold}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Excluded Minutes</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_excluded_total_minutes} className="tooltip_info_icon"/><br></br>
                                <Select
                                    className="input_fields_select"
                                    id="rr_excluded_total_minutes"
                                    placeholder="RR Excluded Minutes"
                                    onChange={(e) => {this.setState({rr_excluded_total_minutes: e.value})}}
                                    options={this.state.rr_excluded_minutes_static}
                                    required
                                /><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_excluded_total_minutes}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                    <Label className="group_label">Others</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Max Case Weight</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_case_weight} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="max_case_weight" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.max_case_weight}</Label><br></br>
                            </Grid>
                            <Grid item xs={5}>
                                <Label className="ticket_parameters">Reference Case Load</Label><Icon glyph="InfoWithCircle" size="small" title={message.reference_case_load} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="reference_case_load" onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.reference_case_load}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="create_model_paramters">
            <div className="parameters_set">
            <Label className="group_label">15 Minutes SLA</Label>
            <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_15_minute_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_15_minute_timeout" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_15_minute_max_attempts" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">30 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_30_minute_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_30_minute_timeout" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_30_minute_max_attempts" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">60 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_60_minute_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_60_minute_timeout" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_60_minute_max_attempts" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">120 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_120_minute_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_120_minute_timeout" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_120_minute_max_attempts" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">240 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_240_minute_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_240_minute_timeout" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_240_minute_max_attempts" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">360 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_360_minute_threshold" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_360_minute_timeout" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="sla_current_360_minute_max_attempts" onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        </div>
        </div>
        </div>
      </form>
    );
  }
}

export default Model;
