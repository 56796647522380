import React from "react";
import { H3, Overline, Subtitle } from "@leafygreen-ui/typography";
import Badge from '@leafygreen-ui/badge';
import Box from "@leafygreen-ui/box";
import "../../css/classicstyle.css";
import "./Loadscore.css";
import { getCurrentUser } from "../../stitch/auth.js";
import { app } from "../../stitch/app";
import { TableWrapper } from '../TableWrapper/TableWrapper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@leafygreen-ui/tooltip';
import Icon from '@leafygreen-ui/icon';

class Loadscore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      userNotFound: false,
      // to be reviewed
      FederationIdentifier: null,
      FirstName: null,
      LastName: null,
      Title: null,
      Workload_Percent__c: null,
      user: null,
      data: null,
      runid_data: null,
      retryCount: 0,
    };
  }

  async componentDidMount() {
    /**
     * If a user logged in opens the tab it will be shown the current user Loadscore breakdown
     * Otherwise if the a user lands on the tab clikcing from tchelper or somehwere else passing a TSE ID it will show that
     * user loadscore breakdown
     */
    console.log('loadscore.componentDidMount');
    this.reprocessData();
  }

  async reprocessData() {
    let prev_user = this.state.id;
    if (this.props && this.props.match && this.props.match.params.id) {
      console.log('user by url');
      this.state.id = this.props.match.params.id;
      this.state.runid_data = null;
      // isNow true because this tab shows the loadscore breakdown as it is now.
    } else if (this.props.userId) {
      let user_id = this.props.userId;
      this.state.runid_data = this.props.data;
      console.log(this.props.data);
      console.log('props by param - runID data');
      //console.log(this.props);
      if (!user_id.endsWith('mongodb.com')) {
        user_id = user_id + '@mongodb.com';
      }
      this.state.id = user_id;

    } else {
      //this.state.id = getCurrentUser().email;
      console.log("Current user");
      let curr_user = getCurrentUser();
      console.log(curr_user);
      console.log(curr_user.profile.email);
      this.state.id = curr_user.profile.email;
      this.state.runid_data = null;
      //this.state.id = 'marco.barbierato@mongodb.com';

    }
    if (this.state.id !== prev_user) {
      console.log("updating table");
      this.setState({ isReady: false });
      await this.prepareAutoTCData();
    } else {
      if (this.state.userNotFound && (this.state.retryCount < 2)) { // retry in this case
        console.log("retrying prev_user=" + prev_user + ' ' + this.state.retryCount);
        this.setState({ isReady: false, retryCount: (this.state.retryCount + 1) });
        await this.prepareAutoTCData();
      } else {
        console.log("not updating prev_user=" + prev_user);
      }
      return;
    }
  }

  async prepareAutoTCData() {
    let user_data = await app.callFunction("getUser", [false, this.state.id]);
    console.log('user_data');
    console.log(user_data);
    if (user_data) {
      this.state.FirstName = user_data.FirstName;
      this.state.LastName = user_data.LastName;
      this.state.Title = user_data.Title;
      this.state.Workload_Percent__c = user_data.Workload_Percent__c * 100;
      this.state.user = user_data;
      this.state.score = user_data.score;
      this.state.score_pre_workload = user_data.score_pre_workload_perc;
    } else {
      this.setState({ userNotFound: true,  isReady: true });
      return;
    }
    if (this.state.runid_data) {
      this.state.data = this.processUserAutoTCData(this.state.runid_data, true);
    } else {
      this.state.data = this.processUserAutoTCData(user_data, false);
    }
    this.setState({ isReady: true, userNotFound: false, retryCount: 0 });
  }


  processUserAutoTCData(user_data, score_from_data) {
    let decay_info =
    {
      's1': {
        'closed': {
          'decay': 60,
          'decay_text': '60min',
          'percentage': 1.66,
          'weight': 45
        },
        'waiting': {
          'decay': 2880,
          'decay_text': '48hrs',
          'max_decayed_score': 5,
          'percentage': 2.08,
          'weight': 10
        }
      },
      's2': {
        'closed': {
          'decay': 30,
          'decay_text': '30min',
          'percentage': 3.3,
          'weight': 30
        },
        'waiting': {
          'decay': 1440,
          'decay_text': '24hrs',
          'max_decayed_score': 2.5,
          'percentage': 4.16,
          'weight': 5
        }
      },
      's3': {
        'closed': {
          'decay': 15,
          'decay_text': '15min',
          'percentage': 6.66,
          'weight': 10
        },
        'waiting': {
          'decay': (12*60), // 12h * 60min
          'decay_text': '12hrs',
          'max_decayed_score': 0.5,
          'percentage': 8.3,
          'weight': 1
        }
      },
      's4': {
        'closed': {
          'decay': 5,
          'decay_text': '5min',
          'percentage': 20,
          'weight': 5
        },
        'waiting': {
          'decay': (6*60),
          'decay_text': '6hrs',
          'max_decayed_score': 0.2,
          'percentage': 16.66,
          'weight': 0.5
        }
      },
      's5': {
        'closed': {
          'decay': 5,
          'decay_text': '5min',
          'percentage': 20,
          'weight': 5
        },
        'waiting': {
          'decay': (6*60),
          'decay_text': '6hrs',
          'max_decayed_score': 0.2,
          'percentage': 16.66,
          'weight': 0.5
        }
      }
    }

    let states_sfdc = ['active', 'closed', 'waiting'];
    let states_dict = {'active': 'In Progress',
                   'closed': 'Closed',
                   'waiting': 'Waiting for Customer'
                  };
    let data = {'SFDC': [], 'HELP': []};

    // SFDC tickets
    let sfdc_total = 0;
    states_sfdc.forEach(state => {
      console.log(state);
      if (!(state in user_data)) {
        console.log('no data in user');
        return;
      }
      for (const [sev, entries] of Object.entries(user_data[state])) {
        entries.forEach(ls_info => {
          let fts_score = 0;
          // orig_score: score before the fts and escalated additions
          let orig_score = ls_info['score'];
          if (ls_info['fts']) {
            if (state == 'active') {
              fts_score = 10;
              orig_score -= 10;
            } else if (state == 'waiting') {
              fts_score = 5;
              orig_score -= 5;
            }
          }
          if (ls_info['escalated']) {
            orig_score -= 10;
          }
          let tkt_info = {
            'sev': sev.toUpperCase(),
            'id': ls_info['case'],
            'status': states_dict[state],
            'status_raw': state,
            'Id': ls_info['Id'],
            'source': ls_info['source'],
            // in seconds
            'status_change': ls_info['elapsed_minutes'] * 60,
            'base_score': ls_info['base_score'],
            'orig_score': orig_score,
            'escalated': ls_info['escalated'] ? "10" : '-',
            'fts': (fts_score || '-').toString(),
            'sfdc': true,
            'score': ls_info['score']};
          sfdc_total += ls_info['score'];
          if (state in decay_info[sev]) {
            let decay_info_curr = decay_info[sev][state]
            let remaining = decay_info_curr['decay'] - ls_info['elapsed_minutes'];
            remaining = remaining > 0 ? remaining : 0;
            tkt_info['remaining_minutes'] = remaining;
            tkt_info['decay_text'] = decay_info_curr.decay_text;
            tkt_info['max_remaining_score'] = decay_info_curr.max_decayed_score;
          } else {
            tkt_info['remaining_minutes'] = 0;
            tkt_info['max_remaining_score'] = 0;
          }
          data['SFDC'].push(tkt_info);
        });
      }
    });
    // we add total as the last line info
    if (data['SFDC'].length > 0) {
      data['SFDC'].push({
        'fts': 'Total',
        'score': sfdc_total
      });
    }
    // HELP tickets
    let help_total = 0;
    for (const [sev, entries] of Object.entries(user_data['help'])) {
      entries.forEach(ls_info => {
        let tkt_info = {
          'prio': sev.toUpperCase(),
          'id': ls_info['case'],
          'status': states_dict['active'],
          'status_raw': 'help',
          // in seconds
          'status_change': 0,
          'escalated': ls_info['escalated'],
          'fts': ls_info['fts'],
          'score': ls_info['score']};
        help_total += ls_info['score'];
        data['HELP'].push(tkt_info);
      });
    }
    if (data['HELP'].length > 0) {
      // we add total as the last line info
      data['HELP'].push({
        'status': 'Total',
        'score': help_total
      });
    }
    if (score_from_data) {
      // we override the scores in the state because
      // we want the score from the runID, not the
      // user score right now
      let total_score = help_total + sfdc_total;
      this.state.score_pre_workload = total_score;
      let perc = this.state.Workload_Percent__c / 100;
      // see TCHelper for the below calculation (simplified here)
      this.state.score = (2 - perc)*total_score;

    }
    return data;
  }

  loadscoreUserInfo(score, base_score) {
    let score_color = {
      1: { "background": "#F9D3C5", "text": "#3D4F58" },
      2: { "background": "#CF4A22", "text": "#FCEBE2" },
      3: { "background": "#B1371F", "text": "#FCEBE2" },
      4: { "background": "#8F221B", "text": "#FCEBE2" },
      5: { "background": "#570B08", "text": "#FCEBE2" },
    }
    let score_range = Math.floor(score / 20);
    score_range = score_range < 1 ? 1 : score_range;
    score_range = score_range > 5 ? 5 : score_range;
    let bg_color = score_color[score_range].background;
    let txt_color = score_color[score_range].text;
    let box_style = { backgroundColor: bg_color };
    let text_style = {
      paddingTop: '10px',
      paddingLeft: '8px',
      fontSize: '14px',
      color: txt_color
    };
    return (<div className="user_loadscore" style={box_style}>
              <div style={text_style}>
                {score.toFixed(1)} ({base_score.toFixed(1)})</div>
            </div>)
  }

  renderUserInfo() {
    let data = this.state.data;
    let status_color = {
      'active': "green",
      'waiting': "lightgray",
      'closed': "lightgray",
      'help': "blue",
    };

    let score = 0, base_score = 0;
    let tse_stats = {};
    this.state.data['SFDC'].forEach((entry) => {
      if (entry['status_raw'] === undefined) {
        return;
      }
      if (!(entry['status_raw'] in tse_stats)) {
        tse_stats[entry['status_raw']] = 1;
      } else {
        tse_stats[entry['status_raw']] += 1;
      }
      score += entry['score'];
      base_score += entry['base_score'];
    });
    this.state.data['HELP'].forEach((entry) => {
      if (entry['status_raw'] === undefined) {
        return;
      }
      if (!(entry['status_raw'] in tse_stats)) {
        tse_stats[entry['status_raw']] = 1;
      } else {
        tse_stats[entry['status_raw']] += 1;
      }
      score += entry['score'];
    });
    let box_style = {
      width: "70%",
      marginTop: "20px"
    }
    return (
      <Box style={box_style}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Overline className={'overline_data'} >Name</Overline>
            <div className={"user_info"}>{this.state.FirstName} {this.state.LastName}</div>
          </Grid>
          <Grid item xs={3}>
            <Overline className={'overline_data'}><span>Loadscore</span>
              <Tooltip
                trigger={
                <span style={{marginLeft: "4px", top: "3px"}}>
                  <Icon className={'load-score-info-2'} size="small" glyph="InfoWithCircle" fill="#89979B" />
                </span>}
                triggerEvent="hover" darkMode={true}
              >The secondary number in a TSE’s <br />
                loadscore is indicative of their score<br />
                with their workload multiplier.
              </Tooltip>
            </Overline>

            {this.loadscoreUserInfo(this.state.score, this.state.score_pre_workload)}
          </Grid>
          <Grid item xs={3}>
            <Overline className={'overline_data'}>Total</Overline>
            {Object.entries(tse_stats).map(([k, v], i) => (
              <React.Fragment key={k}>
              <Badge variant={status_color[k]}>
              {v} {k}
              </Badge><br/>
              </React.Fragment>))
            }
          </Grid>
          <Grid item xs={3}>
            <Overline className={'overline_data'}>Workload</Overline>
            <div className={"user_info"}>{this.state.Workload_Percent__c}%</div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  renderLoadscoreBreakdown() {
    let client = null;
    this.reprocessData();
    console.log('renderLoadscoreBreakdown');
    console.log(this.state.data);
    return (
      <React.Fragment>
        {this.renderUserInfo()}
        <TableWrapper data={this.state.data} client={client} />
      </React.Fragment>
    );
  }

  renderLoading() {
    return (
      <React.Fragment>
        <div></div>
      </React.Fragment>
    )
  }

  renderError() {
    if (this.props.hideNotFound) {
      return (
        <React.Fragment>
          <div></div>
        </React.Fragment>
      );
    } else {
    return (
      <React.Fragment>
        <H3 className="tab-heading">User {this.state.id} not found</H3>
      </React.Fragment>
    );
    }
  }

  render() {
    if (this.props && this.props.userId) {
      this.reprocessData();
    }
    if (this.state.isReady) {
      return (<React.Fragment>
              {this.props.hideNotFound ? <hr className="divider"></hr> : <></>}
              <div className='loadscore_div'>
                <Subtitle className="case_analysis_heading">Loadscore Inspector</Subtitle>
                {this.props.hideNotFound ? <></> : <div>Latest run data</div>}
                {this.state.userNotFound ? this.renderError() : this.renderLoadscoreBreakdown()}
              </div>
              </React.Fragment>
              );
    } else {
      return this.renderLoading();
    }
  }
}
export default Loadscore;
