import { app } from "../stitch/app";
import { GoogleRedirectCredential } from "mongodb-stitch-browser-sdk";

//This file can be used to add multiple authentication platform such as Google, Facebook, Ananymous, etc.

export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app.auth.isLoggedIn;
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app.auth.isLoggedIn ? app.auth.user : null;
}

//The loginGoogle calls the Google's login method
export async function loginGoogle() {
  return await app.auth.loginWithRedirect(new GoogleRedirectCredential());
}

export function addAuthenticationListener(listener) {
  app.auth.addAuthListener(listener);
}

export function removeAuthenticationListener(listener) {
  app.auth.removeAuthListener(listener);
}

export function handleOAuthRedirects() {
  if (app.auth.hasRedirectResult()) {
    return app.auth.handleRedirectResult();
  }
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  const user = getCurrentUser();
  return app.auth.logoutUserWithId(user.id);
}