import React from "react";
import { app } from "../../stitch/app";
import { H2, Subtitle } from "@leafygreen-ui/typography";
import TableContainer from "@material-ui/core/TableContainer";
import { scaleLinear, max, axisBottom, select } from "d3";
import * as d3 from "d3";
import { uiColors } from "@leafygreen-ui/palette";
import "../../css/classicstyle.css";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import CaseAnalyisis from "./caseAnalysis";
import TableFooter, { CaseLabel } from "./tableLabels";
import { connect } from "react-redux";
import $ from "jquery";
import "./runAnalysis.css";
import "./ScatterGraph.css";

class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tseData: {},
      active_table: [],
      scatter_plot: null,
      previousSelected: "",
      previousClass: "",
      previousSelectedTSE: "",
      previousSelectedTSEClass: "",
      graphSelectedTSE: ""
    };
  }

  fetchItem = async (runId) => {
    await app.callFunction("getAutoTCData", [runId]).then(async (res) => {
      let tse_scores = [];
      let tse_names = [];
      let cost_data = [];
      let name_list = [];
      const cases = res[0].solutions[0].autotc_inspector_headers;
      let recommended_tses_sla, recommended_tses_fts, recommended_tses_una;

      if ("solution" in res[0].solutions[0].sla) {
        recommended_tses_sla = res[0].solutions[0].sla.solution;
      } else {
        recommended_tses_sla = {};
      }
      if ("solution" in res[0].solutions[0].fts) {
        recommended_tses_fts = res[0].solutions[0].fts.solution;
      } else {
        recommended_tses_fts = {};
      }
      if ("solution" in res[0].solutions[0].una) {
        recommended_tses_una = res[0].solutions[0].una.solution;
      } else {
        recommended_tses_una = {};
      }

      // Fetching the Load scores of the available TSEs
      var tse_data = {};
      Object.keys(res[0].available_tses).forEach((tse) => {
        var _tse_info = res[0].available_tses[tse];
        tse_data[tse] = Object.assign({
          active: _tse_info['active'],
          closed: _tse_info['closed'],
          help: _tse_info['help'],
          waiting: _tse_info['waiting'],
          fi: _tse_info['FederationIdentifier'],
        });
      });
      var tse_name_list = Object.keys(
        res[0].solutions[0].autotc_inspector_table
      );

      for (let i = 0; i < tse_name_list.length; i++) {
        tse_scores.push(
          Math.round(
            res[0].solutions[0].autotc_inspector_table[tse_name_list[i]][0]
              .score
          )
        );
      }

      // Fetching the list of available TSEs and converting their names to MongoDB alias

      let tse_name_data_dot;
      for (let i = 0; i < tse_name_list.length; i++) {
        tse_name_data_dot = tse_name_list[i].replace("_", ".");
        tse_names.push({name: tse_name_data_dot, fi: tse_data[tse_name_list[i]]['fi'], score: tse_scores[i]})
      }

      // Fetching analyzed cost values for each TSE

      tse_name_list.forEach((element) => {
        var incoming_cost = [];
        for (
          let j = 0;
          j <
          Object.keys(res[0].solutions[0].autotc_inspector_table[element])
            .length;
          j++
        ) {
          const partialData =
            Math.round(res[0].solutions[0].autotc_inspector_table[element][j].total_cost);
          incoming_cost.push(partialData === 100 ? "∞" : partialData);
        }
        cost_data.push(incoming_cost);
      });

      let len = cases.length;
      cost_data.forEach((cost_elem) => {
        let diff = len - cost_elem.length;

        if (diff > 0) {
          let data = Array(diff).fill("-");
          cost_elem.push(...data);
        }
      });

      name_list = tse_names;

      //Fetching data for the Chart Component; Scores are being rounded up in mutiples of 5.

      let chartList = [];
      let counter = 1;

      // Rounds up number to closest multiple of 5, we provide leverage of 1 load-score to be part of previous multiple of 5 i.e. 6 to 5 and 6.5 to 10

      function roundUp_load(n) {
        if (n % 5 === 0) {
          return n;
        } else {
          let num = Math.round((n + 1) / 5) * 5;
          return num;
        }
      }

      for (let i = 0; i < tse_name_list.length; i++) {
        tse_name_data_dot = tse_name_list[i].replace("_", ".");
        let round = roundUp_load(tse_scores[i]);
        let round_next = roundUp_load(tse_scores[i + 1]);

        if (round === round_next) {
          counter += 1;
        } else {
          counter = 1;
        }

        // Counter is being multiplied by constant value(13) to keep pixel distance between stacked circles

        chartList[i] = {
          name: tse_name_data_dot,
          score: tse_scores[i],
          rounded_score: round,
          counter: counter * 13,
        };
      }

      // Fetching Information for the Case Analysis Component

      let final_result = {};
      Object.keys(res[0].solutions[0].autotc_inspector_table).forEach((key) => {
        let partial_result = {};
        Object.values(res[0].solutions[0].autotc_inspector_table[key]).forEach(
          (element) => {
            partial_result[element["CaseNumber"]] = [element];
          }
        );
        final_result[key] = partial_result;
      });

      const solution_info = {
        cost_metrics: cost_data,
        name_list: name_list,
        case_list: cases,
        recommended_tses_sla: recommended_tses_sla,
        recommended_tses_fts: recommended_tses_fts,
        recommended_tses_una: recommended_tses_una,
        tse_load_scores: chartList,
        case_analysis: final_result,
        shortListedTSEs: {
          recommended_tses_sla: recommended_tses_sla,
          recommended_tses_fts: recommended_tses_fts,
          recommended_tses_una: recommended_tses_una,
        },
      };
      await this.setState({ tableData: solution_info });
      await this.setState({ tseData: tse_data, created: res[0].created.toISOString()});
    });

    return this.state.tableData;
  };

  createTable = async (data) => {
    if (Object.keys(data).length > 0) {
      let table = [];
      let row_header = [];
      let row_info = [];
      row_header = [];
      row_header.push(<th id="empty_cell"></th>);
      data.case_list.forEach((element) => {
        if (element.type === "fts") {
          row_header.push(<th id="shrink-header-fts">{element.CaseNumber}</th>);
        } else if (element.type === "una") {
          row_header.push(<th id="shrink-header-una">{element.CaseNumber}</th>);
        } else {
          row_header.push(<th id="shrink-header-sla">{element.CaseNumber}</th>);
        }
      });
      table.push(
        <tHead>
          <tr>{row_header}</tr>
        </tHead>
      );

      // Loop to create table Body

      for (let i = 0; i < data.name_list.length; i++) {
        row_info = [];
        var tse_data = data.name_list[i];
        var tse = tse_data.name;
        row_info.push(<td className="tse_names">
                        <a href={'/loadscore/'+tse_data.fi}>{tse_data.name}</a> / {tse_data.score}</td>);
        for (let j = 0; j < data.case_list.length; j++) {
          let caseNumber = data.case_list[j].CaseNumber;
          if (
            caseNumber in data.recommended_tses_sla &&
            data.recommended_tses_sla[caseNumber] === tse
          ) {
            row_info.push(
              <td
                id={caseNumber}
                className="shrink_selected_sla_run"
                align="center"
              >
                {data.cost_metrics[i][j]}
              </td>
            );
          } else if (
            caseNumber in data.recommended_tses_fts &&
            data.recommended_tses_fts[caseNumber] === tse
          ) {
            row_info.push(
              <td
                id={caseNumber}
                className="shrink_selected_fts"
                name={tse}
                align="center"
              >
                {data.cost_metrics[i][j]}
              </td>
            );
          } else if (
            caseNumber in data.recommended_tses_una &&
            data.recommended_tses_una[caseNumber] === tse
          ) {
            row_info.push(
              <td
                id={caseNumber}
                className="shrink_selected_una"
                align="center"
              >
                {data.cost_metrics[i][j]}
              </td>
            );
          } else {
            row_info.push(
              <td id={caseNumber} className="shrink" align="center">
                {data.cost_metrics[i][j]}
              </td>
            );
          }
        }
        table.push(<tr>{row_info}</tr>);
      }
      return table;
    }
  };

  scatterPlot = async (tableData) => {
    if (Object.keys(tableData).length > 0) {
      const margin = { top: 0, right: 15, bottom: 60, left: 20 };
      const width = 700 - margin.left - margin.right;
      const height = 380 - margin.top - margin.bottom;

      //Assigning data to calculate axis measurements

      let data = [];
      if (tableData.tse_load_scores) {
        tableData.tse_load_scores.forEach((tse) => {
          data.push(tse.score);
        });
      }

      //Calculating the min and max for the X & Y

      const extent_x = d3.extent(data);
      const extent_y = max(data);

      const x = scaleLinear().domain(extent_x).range([0, width]).nice();

      const y = scaleLinear().domain([0, extent_y]).range([height, 0]).nice();

      return (
        <React.Fragment>
          <Subtitle className="graph_heading">
            TSEs considered by AutoTC
          </Subtitle>
          <svg
            width={width + margin.right + margin.left}
            height={height + margin.bottom}
            className="chart"
          >
            <g
              transform={"translate(" + margin.left + "," + margin.top + ")"}
              width={width}
              height={height}
              className="main"
            >
              <Axis
                axis="x"
                transform={"translate(0," + height + ")"}
                scale={axisBottom().scale(x)}
              />
              <RenderCircles selectedTSE={tableData} clickedTSE={this.state.graphSelectedTSE} scale={{ x, y }} />
            </g>
          </svg>
        </React.Fragment>
      );
    }
  };

  componentDidMount() {
    const runId = this.props.match.params.id;
    this.setState({ runId: runId });
    this.fetchItem(runId).then(async (res) => {
      await this.setState({ tableData: res });
      await this.createTable(this.state.tableData).then((res) => {
        this.setState({ active_table: res });
      });
      await this.scatterPlot(this.state.tableData).then(async (res) => {
        await this.setState({ scatter_plot: res });
      });
    });

    //Highlighting the selected case and setting back the original color to previously selected case

    const checkPreviousCell = async (val, currentTse) => {
      let prevClass;
      let tseClass;
      if (
        this.state.previousSelected === "" ||
        this.state.previousSelected === val
      ) {
        $(val).removeClass(this.state.previousClass);
        $(currentTse).removeClass(this.state.previousSelectedTSEClass);
        prevClass = $(val).className;
        tseClass = $(currentTse).className;
        $(val).addClass("selectedCell");
        $(currentTse).addClass("selectedTSECell");
        this.setState({
          previousSelected: val,
          previousClass: prevClass,
          previousSelectedTSE: currentTse,
          previousSelectedTSEClass: tseClass,
        });
      } else if (
        this.state.previousSelected !== "" &&
        this.state.previousSelected === val
      ) {
        console.log("Same Clicked");
      } else {
        $(this.state.previousSelected).removeClass("selectedCell");
        $(this.state.previousSelectedTSE).removeClass("selectedTSECell");
        $(this.state.previousSelected).addClass(this.state.previousClass);
        $(this.state.previousSelectedTSE).addClass(
          this.state.previousSelectedTSEClass
        );
        $(val).addClass("selectedCell");
        $(currentTse).addClass("selectedTSECell");
        this.setState({
          previousSelected: val,
          previousClass: prevClass,
          previousSelectedTSE: currentTse,
          previousSelectedTSEClass: tseClass,
        });
      }
    };

    //Handling onClick event for the Metrics Table

    let clickedCase = 0;
    $(".evaluation_table").on("click", "td", function (e) {
      var theCase = e.delegateTarget.tHead.rows[0].cells[this.cellIndex],
        currentTse = this.parentNode.cells[0],
        val = this.parentNode.cells[this.cellIndex];
      if (
        $(val).text() === "-" ||
        $(val).text() === "∞" ||
        currentTse === val
      ) {
        return 0;
      } else {
        checkPreviousCell(val, currentTse);
        var tseWithLoad = $(currentTse).text();
        var tse = tseWithLoad.split(" ", 1).toString();
        clickedCase = $(theCase).text();
        assignData(clickedCase, tse);
      }
    });

    //Sending Information to the CaseAnalysis Component

    const assignData = async(clickedCase, tse) => {
      this.setState({graphSelectedTSE: tse});
      let clickedTSE = tse.replace(".", "_");
      console.log('clicked tse:' + clickedTSE);
      let case_details = this.state.tableData.case_analysis[clickedTSE][clickedCase][0];
      let curr_tse_data = this.state.tseData[clickedTSE];
      this.props.onClickProp(case_details, tse, curr_tse_data);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="div_left">
          <H2 className="heading">{`Run ID: ${this.state.runId}`}</H2>
          <div className="runid_timestamp">Run date: {this.state.created}</div>
          <div className="run_analysis_label">
            <Subtitle>Run Analysis</Subtitle>
            <p className="run_explaination">
              The following analysis is solely for the Run ID mentioned
              above. The analysis consists of three components: Cost Calculator,
              Case Assignment Cost, and Graph Analysis. These components should
              help you understand the calculation of the case assignment, which
              factors were considered for the recommendation, and why it was not
              recommended to somebody else.
            </p>
          </div>
        </div>
        <hr className="divider"></hr>
        <div>
          <CaseAnalyisis />
        </div>
        <hr className="divider"></hr>
        <div className="div_left">
          <Subtitle>Case Assignment Cost</Subtitle>
          <TableContainer>
            <CaseLabel></CaseLabel>
            <div className="tsename_label">TSE Name / Loadscore</div>
            <div className="table_div">
              <table className="evaluation_table" cellspacing="0">
                {this.state.active_table}
              </table>
            </div>
          </TableContainer>
        </div>
        <div className="table_label_bottom">
          <TableFooter></TableFooter>
        </div>
        <hr className="divider"></hr>
        <div className="div_left">
          <div className="graph_position">{this.state.scatter_plot}</div>
        </div>
        <div className="div_right"></div>
      </React.Fragment>
    );
  }
}

class RenderCircles extends React.Component {
  render() {
    if (Object.keys(this.props.selectedTSE).length > 0) {
      const style_others = {
        fill: uiColors.gray.light1,
      };
      const style_sla = {
        fill: uiColors.blue.light2,
      };
      const style_fts = {
        fill: uiColors.red.light2,
      };

      const style_una = {
        fill: uiColors.yellow.light2,
      };

      const style_selected = {
        fill: uiColors.green.light2,
        stroke: uiColors.green.base,
        strokeWidth: "2.5",
      };

      const popup_box = {
        tooltip: {
          width: "auto",
          height: "auto",
          fontSize: "13px",
        },
      };

      const PopUpBox = withStyles(popup_box)(Tooltip);
      let renderCircles = [];
      this.props.selectedTSE.tse_load_scores.forEach((tse) => {
        if (
          Object.values(
            this.props.selectedTSE.shortListedTSEs.recommended_tses_sla
          ).includes(tse.name)
        ) {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.blue.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE.recommended_tses_sla === tse.name
                    ? style_selected
                    : style_sla
                }
                id="sla_circles"
              />
            </PopUpBox>
          );
        } else if (
          Object.values(
            this.props.selectedTSE.shortListedTSEs.recommended_tses_fts
          ).includes(tse.name)
        ) {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.red.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE.recommended_tses_fts === tse.name
                    ? style_selected
                    : style_fts
                }
              />
            </PopUpBox>
          );
        } else if (
          Object.values(
            this.props.selectedTSE.shortListedTSEs.recommended_tses_una
          ).includes(tse.name)
        ) {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.yellow.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE.recommended_tses_una === tse.name
                    ? style_selected
                    : style_una
                }
              />
            </PopUpBox>
          );
        } else {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.gray.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE === tse.name
                    ? style_selected
                    : style_others
                }
                id="style_others"
              />
            </PopUpBox>
          );
        }
      });
      return <g>{renderCircles}</g>;
    }
  }
}

class Axis extends React.Component {
  state = {
    transform_value: null,
  };

  componentDidMount() {
    this.setState({ transform_value: this.props.transform });
  }

  render() {
    const node = this.refs[this.props.axis];
    select(node).call(this.props.scale);

    const style_axis = {
      color: uiColors.black,
    };

    return (
      <React.Fragment>
        <g
          className="main axis date"
          transform={this.state.transform_value}
          ref={this.props.axis}
          style={style_axis}
          stroke-width="2.5"
          strokeLinecap="butt"
        />
      </React.Fragment>
    );
  }
}


// Highlight the selected TSE

const mapStateToProps = (state) => {
  return {
    caseTSE: state.tse,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClickProp: (case_details, incomingTSE, tse_info) => dispatch({
      type: 'UPDATE',
      newCase: case_details['CaseNumber'] + " : ",
      tse: incomingTSE,
      tse_info: Object.assign({}, tse_info),
      description: case_details['case_subj'],
      sev: case_details['severity'] + " : ",
      task_components: [...case_details['task_components']],
      skills: [...case_details['skill_matches']],
      skill_bonus: case_details['skill_full_bonus_v'],
      skill_bonus_percentage: case_details['skill_full_bonus_%'],
      ntse_bonus: case_details['ntse_bonus_v'],
      ntse_bonus_percentage: case_details['ntse_bonus_%'],
      atlas_penalty: case_details['atlas_penalty_v'],
      atlas_penalty_percentage: case_details['atlas_penalty_%'],
      score: case_details['tse_load_v'].toFixed(2),
      score_percentage: case_details['tse_load_%'],
      total_cost: case_details['total_cost'].toFixed(2),
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);