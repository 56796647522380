import React from "react";
import { app } from "../../stitch/app";
import { H2, Subtitle } from "@leafygreen-ui/typography";
import TableContainer from "@material-ui/core/TableContainer";
import { scaleLinear, max, axisBottom, select } from "d3";
import * as d3 from "d3";
import { uiColors } from "@leafygreen-ui/palette";
import "../../css/classicstyle.css";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import CaseAnalyisis from "../analysisPage/caseAnalysis";
import CompareAnalysis from "../analysisPage/CompareAnalysis";
import TableFooter, { CaseLabel } from "../analysisPage/tableLabels";
import { connect } from "react-redux";

// import ViewModel from "../modelComponent/ViewModel";
import $ from "jquery";
import "./CompareModel.css";

class CompareModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      // Run ID of the analysis
      runId: null,

      // List of the active and inactive models
      inactive_solutions: [],
      active_solutions: [],

      // Active model analysis
      tableData: null,
      active_table: [],
      scatter_plot: null,

      // Handle Comparison Component
      compare_table: [],
      compare_tableData: null,
      compare: [],
      compare_scatter_plot: null,

      // Handle cell onClick
      previousSelected: "",
      previousClass: "",
      previousSelectedTSE: "",
      previousSelectedTSEClass: "",

      // Handle mirror effect 
      previousCompareSelected: "",
      previousCompareClass: "",
      previousCompareSelectedTSE: "",
      previousCompareSelectedTSEClass: "",

      // Compare Analysis data
      compareTableData: null,
      compare_case_analysis: ""
    };
  }

  fetchItem = async (runId) => {
    await app.callFunction("getAutoTCData", [runId]).then(async (res) => {
      let tse_scores = [];
      let tse_names = [];
      let cost_data = [];
      let name_list = [];
      const cases = res[0].solutions[0].autotc_inspector_headers;
      let recommended_tses_sla, recommended_tses_fts, recommended_tses_una;

      if ("solution" in res[0].solutions[0].sla) {
        recommended_tses_sla = res[0].solutions[0].sla.solution;
      } else {
        recommended_tses_sla = {};
      }
      if ("solution" in res[0].solutions[0].fts) {
        recommended_tses_fts = res[0].solutions[0].fts.solution;
      } else {
        recommended_tses_fts = {};
      }
      if ("solution" in res[0].solutions[0].una) {
        recommended_tses_una = res[0].solutions[0].una.solution;
      } else {
        recommended_tses_una = {};
      }

      // Fetching the Load scores of the available TSEs

      var tse_name_list = Object.keys(
        res[0].solutions[0].autotc_inspector_table
      );

      for (let i = 0; i < tse_name_list.length; i++) {
        tse_scores.push(
          Math.round(
            res[0].solutions[0].autotc_inspector_table[tse_name_list[i]][0]
              .score
          )
        );
      }

      // Fetching the list of available TSEs and converting their names to MongoDB alias

      let tse_name_data_dot;
      for (let i = 0; i < tse_name_list.length; i++) {
        tse_name_data_dot = tse_name_list[i].replace("_", ".");
        tse_names.push(tse_name_data_dot + " / " + tse_scores[i]);
      }

      // Fetching analyzed cost values for each TSE

      tse_name_list.forEach((element) => {
        var incoming_cost = [];
        for (
          let j = 0;
          j <
          Object.keys(res[0].solutions[0].autotc_inspector_table[element])
            .length;
          j++
        ) {
          const partialData =
            Math.round(res[0].solutions[0].autotc_inspector_table[element][j].total_cost);
          incoming_cost.push(partialData === 100 ? "∞" : partialData);
        }
        cost_data.push(incoming_cost);
      });

      let len = cases.length;
      cost_data.forEach((cost_elem) => {
        let diff = len - cost_elem.length;

        if (diff > 0) {
          let data = Array(diff).fill("-");
          cost_elem.push(...data);
        }
      });

      name_list = tse_names;

      //Fetching data for the Chart Component; Scores are being rounded up in mutiples of 5.

      let chartList = [];
      let counter = 1;

      // Rounds up number to closest multiple of 5, we provide leverage of 1 load-score to be part of previous multiple of 5 i.e. 6 to 5 and 6.5 to 10

      function roundUp_load(n) {
        if (n % 5 === 0) {
          return n;
        } else {
          let num = Math.round((n + 1) / 5) * 5;
          return num;
        }
      }

      for (let i = 0; i < tse_name_list.length; i++) {
        tse_name_data_dot = tse_name_list[i].replace("_", ".");
        let round = roundUp_load(tse_scores[i]);
        let round_next = roundUp_load(tse_scores[i + 1]);

        if (round === round_next) {
          counter += 1;
        } else {
          counter = 1;
        }

        // Counter is being multiplied by constant value(13) to keep pixel distance between stacked circles

        chartList[i] = {
          name: tse_name_data_dot,
          score: tse_scores[i],
          rounded_score: round,
          counter: counter * 13,
        };
      }

      //Fetching Information for the Case Analysis Component

      let final_result = {};
      Object.keys(res[0].solutions[0].autotc_inspector_table).forEach((key) => {
        let partial_result = {};
        Object.values(res[0].solutions[0].autotc_inspector_table[key]).forEach(
          (element) => {
            partial_result[element["CaseNumber"]] = [element];
          }
        );
        final_result[key] = partial_result;
      });

      const solution_info = {
        cost_metrics: cost_data,
        name_list: name_list,
        case_list: cases,
        recommended_tses_sla: recommended_tses_sla,
        recommended_tses_fts: recommended_tses_fts,
        recommended_tses_una: recommended_tses_una,
        tse_load_scores: chartList,
        case_analysis: final_result,
        shortListedTSEs: {
          recommended_tses_sla: recommended_tses_sla,
          recommended_tses_fts: recommended_tses_fts,
          recommended_tses_una: recommended_tses_una,
        },
      };
      await this.setState({ tableData: solution_info });
    });

    return this.state.tableData;
  };

  fetchItemToCompare = async (runId, model_id) => {
    await app
      .callFunction("getCompareDataSolutions", [runId, model_id])
      .then(async (res) => {
        let tse_scores = [];
        let tse_names = [];
        let cost_data = [];
        let name_list = [];
        const cases = res[0].solutions[0].autotc_inspector_headers;
        let recommended_tses_sla, recommended_tses_fts, recommended_tses_una;

        if ("solution" in res[0].solutions[0].sla) {
          recommended_tses_sla = res[0].solutions[0].sla.solution;
        } else {
          recommended_tses_sla = {};
        }
        if ("solution" in res[0].solutions[0].fts) {
          recommended_tses_fts = res[0].solutions[0].fts.solution;
        } else {
          recommended_tses_fts = {};
        }
        if ("solution" in res[0].solutions[0].una) {
          recommended_tses_una = res[0].solutions[0].una.solution;
        } else {
          recommended_tses_una = {};
        }

        // Fetching the Load scores of the available TSEs

        var tse_name_list = Object.keys(
          res[0].solutions[0].autotc_inspector_table
        );

        for (let i = 0; i < tse_name_list.length; i++) {
          tse_scores.push(
            Math.round(
              res[0].solutions[0].autotc_inspector_table[tse_name_list[i]][0]
                .score
            )
          );
        }

        // Fetching the list of available TSEs and converting their names to MongoDB alias

        let tse_name_data_dot;
        for (let i = 0; i < tse_name_list.length; i++) {
          tse_name_data_dot = tse_name_list[i].replace("_", ".");
          tse_names.push(tse_name_data_dot + " / " + tse_scores[i]);
        }

        // Fetching analyzed cost values for each TSE

        tse_name_list.forEach((element) => {
          var incoming_cost = [];
          for (
            let j = 0;
            j <
            Object.keys(res[0].solutions[0].autotc_inspector_table[element])
              .length;
            j++
          ) {
            const partialData =
            Math.round(res[0].solutions[0].autotc_inspector_table[element][j]
                .total_cost);
            incoming_cost.push(partialData === 100 ? "∞" : partialData);
          }
          cost_data.push(incoming_cost);
        });

        let len = cases.length;
        cost_data.forEach((cost_elem) => {
          let diff = len - cost_elem.length;

          if (diff > 0) {
            let data = Array(diff).fill("-");
            cost_elem.push(...data);
          }
        });

        name_list = tse_names;

        //Fetching data for the Chart Component; Scores are being rounded up in mutiples of 5.

        let chartList = [];
        let counter = 1;

        // Rounds up number to closest multiple of 5, we provide leverage of 1 load-score to be part of previous multiple of 5 i.e. 6 to 5 and 6.5 to 10

        function roundUp_load(n) {
          if (n % 5 === 0) {
            return n;
          } else {
            let num = Math.round((n + 1) / 5) * 5;
            return num;
          }
        }

        for (let i = 0; i < tse_name_list.length; i++) {
          tse_name_data_dot = tse_name_list[i].replace("_", ".");
          let round = roundUp_load(tse_scores[i]);
          let round_next = roundUp_load(tse_scores[i + 1]);

          if (round === round_next) {
            counter += 1;
          } else {
            counter = 1;
          }

          // Counter is being multiplied by constant value(13) to keep pixel distance between stacked circles

          chartList[i] = {
            name: tse_name_data_dot,
            score: tse_scores[i],
            rounded_score: round,
            counter: counter * 13,
          };
        }

        //Fetching Information for the Case Analysis Component

        let final_result = {};
        Object.keys(res[0].solutions[0].autotc_inspector_table).forEach(
          (key) => {
            let partial_result = {};
            Object.values(
              res[0].solutions[0].autotc_inspector_table[key]
            ).forEach((element) => {
              partial_result[element["CaseNumber"]] = [element];
            });
            final_result[key] = partial_result;
          }
        );

        const solution_info = {
          cost_metrics: cost_data,
          name_list: name_list,
          case_list: cases,
          recommended_tses_sla: recommended_tses_sla,
          recommended_tses_fts: recommended_tses_fts,
          recommended_tses_una: recommended_tses_una,
          tse_load_scores: chartList,
          case_analysis: final_result,
          shortListedTSEs: {
            recommended_tses_sla: recommended_tses_sla,
            recommended_tses_fts: recommended_tses_fts,
            recommended_tses_una: recommended_tses_una,
          },
        };
        await this.setState({ compareTableData: solution_info });
      });

    return this.state.compareTableData;
  };

  updateModelInformation = async (runId) => {
    await app
      .callFunction("getModelSolutionsList", [runId])
      .then(async (res) => {
        res.inactive.forEach((sol) => {
          const act = {
            value: sol.model_id,
            label: sol.model_name,
          };
        this.setState({
            inactive_solutions: [...this.state.inactive_solutions, act],
          });
        });
        res.active.forEach(async (sol) => {
        await this.state.active_solutions.push({
            value: sol.model_id,
            label: "Active Model: " + sol.model_name,
          });
        });
      });
  };

  createTable = async (data) => {
    if (Object.keys(data).length > 0) {
      let table = [];
      let row_header = [];
      let row_info = [];
      row_header = [];
      row_header.push(<th id="empty_cell"></th>);
      data.case_list.forEach((element) => {
        if (element.type === "fts") {
          row_header.push(<th id="shrink-header-fts">{element.CaseNumber}</th>);
        } else if (element.type === "una") {
          row_header.push(<th id="shrink-header-una">{element.CaseNumber}</th>);
        } else {
          row_header.push(<th id="shrink-header-sla">{element.CaseNumber}</th>);
        }
      });
      table.push(
        <tHead>
          <tr>{row_header}</tr>
        </tHead>
      );

      // Loop to create table Body

      for (let i = 0; i < data.name_list.length; i++) {
        row_info = [];
        var tse = data.name_list[i].split(" ", 1).toString();
        row_info.push(<td className="tse_names">{data.name_list[i]}</td>);
        for (let j = 0; j < data.case_list.length; j++) {
          let caseNumber = data.case_list[j].CaseNumber;
          if (
            caseNumber in data.recommended_tses_sla &&
            data.recommended_tses_sla[caseNumber] === tse
          ) {
            row_info.push(
              <td
                id={caseNumber+tse}
                className="shrink_selected_sla"
                align="center"
              >
                {data.cost_metrics[i][j]}
              </td>
            );
          } else if (
            caseNumber in data.recommended_tses_fts &&
            data.recommended_tses_fts[caseNumber] === tse
          ) {
            row_info.push(
              <td
                id={caseNumber+tse}
                className="shrink_selected_fts"
                name={tse}
                align="center"
              >
                {data.cost_metrics[i][j]}
              </td>
            );
          } else if (
            caseNumber in data.recommended_tses_una &&
            data.recommended_tses_una[caseNumber] === tse
          ) {
            row_info.push(
              <td
                id={caseNumber+tse}
                className="shrink_selected_una"
                align="center"
              >
                {data.cost_metrics[i][j]}
              </td>
            );
          } else {
            row_info.push(
              <td 
                id={caseNumber+tse}
                className="shrink" align="center">
                {data.cost_metrics[i][j]}
              </td>
            );
          }
        }
        table.push(<tr>{row_info}</tr>);
      }
      return table;
    }
  };

  scatterPlot = async (tableData) => {
    if (Object.keys(tableData).length > 0) {
      const margin = { top: 0, right: 15, bottom: 60, left: 20 };
      const width = 700 - margin.left - margin.right;
      const height = 380 - margin.top - margin.bottom;

      //Assigning data to calculate axis measurements

      let data = [];
      if (tableData.tse_load_scores) {
        tableData.tse_load_scores.forEach((tse) => {
          data.push(tse.score);
        });
      }

      //Calculating the min and max for the X & Y

      const extent_x = d3.extent(data);
      const extent_y = max(data);

      const x = scaleLinear().domain(extent_x).range([0, width]).nice();

      const y = scaleLinear().domain([0, extent_y]).range([height, 0]).nice();

      return (
        <React.Fragment>
          <Subtitle className="graph_heading">
            TSEs considered by AutoTC
          </Subtitle>
          <svg
            width={width + margin.right + margin.left}
            height={height + margin.bottom}
            className="chart"
          >
            <g
              transform={"translate(" + margin.left + "," + margin.top + ")"}
              width={width}
              height={height}
              className="main"
            >
              <Axis
                axis="x"
                transform={"translate(0," + height + ")"}
                scale={axisBottom().scale(x)}
              />
              <RenderCircles selectedTSE={tableData} scale={{ x, y }} />
            </g>
          </svg>
        </React.Fragment>
      );
    }
  };

  compare_model = (compare_model_id) => {
    if (compare_model_id) {
      const model = "" + compare_model_id;
      this.fetchItemToCompare(this.state.runId, model).then(async (res) => {
        await this.setState({ compare_tableData: res });
        await this.createTable(this.state.compare_tableData).then((res) => {
          this.setState({ compare_table: res });
        });
        await this.scatterPlot(this.state.compare_tableData).then((res) => {
          this.setState({ compare_scatter_plot: res });
        });
      });
      document.getElementById("compare_model").style.display = "inline-block";
    } else {
      console.log("No value");
    }
  };

  async componentDidMount() {
    const runId = this.props.match.params.id;
    await this.updateModelInformation(runId);
    this.setState({ runId: runId });
    this.fetchItem(runId).then(async (res) => {
      await this.setState({ tableData: res });
      await this.createTable(this.state.tableData).then((res) => {
        this.setState({ active_table: res });
      });
      await this.scatterPlot(this.state.tableData).then(async (res) => {
        await this.setState({ scatter_plot: res });
      });
    });

    const checkPreviousCell = async (val, compare_cell, currentTse, currentCompareTse) => {
      if(val === this.state.previousSelected){
        return 0;
      }
      let prevClass = val.className;
      let tseClass = currentTse.className;
      let prevCompareClass = (compare_cell) ? compare_cell.className : null;
      let tseCompareClass = (currentCompareTse) ? currentCompareTse.className : null;

      if(this.state.previousSelected === "" && this.state.previousCompareClass === "") {
        // $(compare_cell).removeClass(prevCompareClass);
        $(currentTse).removeClass(this.state.previousSelectedTSEClass);
        // $(currentCompareTse).removeClass(this.state.prev);
        $(val).addClass('selectedCell');
        (compare_cell) ? $(compare_cell).addClass('selectedCell') : console.log("Nothing to compare");
        $(currentTse).addClass('selectedTSECell');
        (currentCompareTse) ? $(currentCompareTse).addClass('selectedTSECell') : console.log("Nothing to compare");

        this.setState({
          previousSelected: val, previousClass: prevClass,
          previousSelectedTSE: currentTse, previousSelectedTSEClass: tseClass,
          previousCompareSelected: compare_cell, previousCompareClass: prevCompareClass,
          previousCompareSelectedTSE: currentCompareTse, previousCompareSelectedTSEClass: tseCompareClass
        });

      } else {
        
        $(this.state.previousSelected).removeClass('selectedCell');
        $(this.state.previousSelectedTSE).removeClass('selectedTSECell');
        $(this.state.previousCompareSelected).removeClass('selectedCell');
        $(this.state.previousCompareSelectedTSE).removeClass('selectedTSECell');

        $(this.state.previousSelected).addClass(this.state.previousClass);
        $(this.state.previousCompareSelected).addClass(this.state.previousCompareClass);

        (tseClass === currentTse.className) ? console.log("Same TSE selected") : $(this.state.previousSelectedTSE).addClass(this.state.previousSelectedTSEClass);
        
        $(val).addClass('selectedCell');
        $(currentTse).addClass('selectedTSECell');
        $(compare_cell).addClass('selectedCell');
        $(currentCompareTse).addClass('selectedTSECell');

        this.setState({
          previousSelected: val, previousClass: prevClass,
          previousSelectedTSE: currentTse, previousSelectedTSEClass: tseClass,
          previousCompareSelected: compare_cell, previousCompareClass: prevCompareClass,
          previousCompareSelectedTSE: currentCompareTse, previousCompareSelectedTSEClass: tseCompareClass
        });
      }
    }

  //Handling onClick event for the Metrics Table
    
    let clickedCase = 0;
    $(".evaluation_table").on('click', 'td', function(e) {
      var theCase = e.delegateTarget.tHead.rows[0].cells[this.cellIndex],
      currentTse  = this.parentNode.cells[0],
      val = this.parentNode.cells[this.cellIndex];
      if($(val).text() === '-' || $(val).text() === '∞' || $(val).text() === $(currentTse).text() ){
        return 0;
      }
      else{
        const cell = document.querySelectorAll(`[id = '${`${e.target.id}`}']`);
        const currentCompareTse = (cell[1]) ? cell[1].parentNode.cells[0] : null;
        checkPreviousCell(val, cell[1], currentTse, currentCompareTse);
        var tseWithLoad = $(currentTse).text();
        var tse = tseWithLoad.split(" ", 1).toString();
        clickedCase = $(theCase).text();
        assignData(clickedCase, tse);
      }
    });


    //Handling onClick events for the Comparison Metrics

    $(".compare_evaluation_table").on('click', 'td', function(e) {
      var theCase = e.delegateTarget.tHead.rows[0].cells[this.cellIndex],
      currentTse  = this.parentNode.cells[0],
      val = this.parentNode.cells[this.cellIndex];
      if($(val).text() === '-' || $(val).text() === '∞' || $(val).text() === $(currentTse).text() ){
        return 0;
      }
      else{
        const cell = document.querySelectorAll(`[id = '${`${e.target.id}`}']`);
        const currentCompareTse = (cell[1]) ? cell[1].parentNode.cells[0] : null;
        checkPreviousCell(val, cell[0], currentTse, currentCompareTse);
        var tseWithLoad = $(currentTse).text();
        var tse = tseWithLoad.split(" ", 1).toString();
        clickedCase = $(theCase).text();
        assignData(clickedCase, tse);
      }
    });

    //Sending Information to the CaseAnalysis Component

    const assignData = async (clickedCase, tse) => {
      let clickedTSE = tse.replace(".", "_");
      let case_details = this.state.tableData.case_analysis[clickedTSE][clickedCase][0];
      if(this.state.compareTableData && this.state.compareTableData.case_analysis) {
        let compare_case_details = this.state.compareTableData.case_analysis[clickedTSE][clickedCase][0];
        // await this.setState({compare_case_analysis: compare_case_details});
        await this.setState(prevState => {
          let compare_case_analysis = Object.assign({}, compare_case_details);
          compare_case_analysis.tse_name = tse;
          return { compare_case_analysis };
        });
      }
      this.props.onClickProp(case_details, tse);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="heading_div">
          <H2 className="heading">{`Comparing Run ID: ${this.state.runId}`}</H2>
        </div>
        <div className="top_div">
            <AsyncSelect
              id="selected_model"
              className="dropdown"
              defaultValue={this.state.active_solutions}
              name="active_model"
            />
            <Select
              className="compare_dropdown"
              isSearchable
              name="inactive_models"
              onChange={(e) => this.compare_model(e.value)}
              options={this.state.inactive_solutions}
            />
        </div>
        <div className="model_div_left">
          <div className="active_model">
            <TableContainer>
              <CaseLabel></CaseLabel>
              <div className="tsename_label_compare">TSE Name / Loadscore</div>
              <div className="compare_table_div">
              <table className="evaluation_table" cellspacing="0">
                {this.state.active_table}
              </table>
              </div>
            </TableContainer>
            <div className="table_label_bottom">
              <TableFooter></TableFooter>
            </div>
            <hr className="component_divide"></hr>
            <div className="graph_position">{this.state.scatter_plot}</div>
            <hr className="component_divide"></hr>
            <CaseAnalyisis></CaseAnalyisis>
            <hr className="component_divide"></hr>
          </div>
        </div>
        <div id="compare_model" className="comapre_model_div_right">
          <div className="active_model">
            <TableContainer>
              <CaseLabel></CaseLabel>
              <div className="tsename_label_compare">TSE Name / Loadscore</div>
              <div className="compare_table_div">
              <table id="compare_evaluation_table" className="compare_evaluation_table" cellspacing="0">
                {this.state.compare_table}
              </table>
              </div>
            </TableContainer>
            <div className="table_label_bottom">
              <TableFooter></TableFooter>
            </div>
            <hr className="component_divide"></hr>
            <div className="graph_position">
              {this.state.compare_scatter_plot}
            </div>
            <hr className="component_divide"></hr>
            <CompareAnalysis case_details = {this.state.compare_case_analysis} ></CompareAnalysis>
            <hr className="component_divide"></hr>
            {/* <ViewModel id = {this.props.match.params.id}></ViewModel> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class RenderCircles extends React.Component {
  render() {
    if (Object.keys(this.props.selectedTSE).length > 0) {
      const style_others = {
        fill: uiColors.gray.light1,
      };
      const style_sla = {
        fill: uiColors.blue.light2,
      };
      const style_fts = {
        fill: uiColors.red.light2,
      };

      const style_una = {
        fill: uiColors.yellow.light2,
      };

      const style_selected = {
        fill: uiColors.green.light2,
        stroke: uiColors.green.base,
        strokeWidth: "2.5",
      };

      const popup_box = {
        tooltip: {
          width: "auto",
          height: "auto",
          fontSize: "13px",
        },
      };

      const PopUpBox = withStyles(popup_box)(Tooltip);
      let renderCircles = [];
      this.props.selectedTSE.tse_load_scores.forEach((tse) => {
        if (
          Object.values(
            this.props.selectedTSE.shortListedTSEs.recommended_tses_sla
          ).includes(tse.name)
        ) {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.blue.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE.recommended_tses_sla === tse.name
                    ? style_selected
                    : style_sla
                }
                id="sla_circles"
              />
            </PopUpBox>
          );
        } else if (
          Object.values(
            this.props.selectedTSE.shortListedTSEs.recommended_tses_fts
          ).includes(tse.name)
        ) {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.red.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE.recommended_tses_fts === tse.name
                    ? style_selected
                    : style_fts
                }
              />
            </PopUpBox>
          );
        } else if (
          Object.values(
            this.props.selectedTSE.shortListedTSEs.recommended_tses_una
          ).includes(tse.name)
        ) {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.yellow.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE.recommended_tses_una === tse.name
                    ? style_selected
                    : style_una
                }
              />
            </PopUpBox>
          );
        } else {
          renderCircles.push(
            <PopUpBox
              title={tse.name + " - " + tse.score}
              enterDelay={50}
              leaveDelay={200}
              placement="top"
            >
              <circle
                cx={this.props.scale.x(tse.rounded_score)}
                cy={this.props.scale.y(tse.counter)}
                r="6"
                stroke={uiColors.gray.dark2}
                stroke-width="0.8"
                style={
                  this.props.selectedTSE === tse.name
                    ? style_selected
                    : style_others
                }
                id="style_others"
              />
            </PopUpBox>
          );
        }
      });
      return <g>{renderCircles}</g>;
    }
  }
}

class Axis extends React.Component {
  state = {
    transform_value: null,
  };

  componentDidMount() {
    this.setState({ transform_value: this.props.transform });
  }

  render() {
    const node = this.refs[this.props.axis];
    select(node).call(this.props.scale);

    const style_axis = {
      color: uiColors.black,
    };

    return (
      <React.Fragment>
        <g
          className="main axis date"
          transform={this.state.transform_value}
          ref={this.props.axis}
          style={style_axis}
          stroke-width="2.5"
          strokeLinecap="butt"
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickProp: (case_details, incomingTSE) => dispatch({
      type: 'UPDATE',
      newCase: case_details['CaseNumber'] + " : ",
      tse: incomingTSE,
      description: case_details['case_subj'],
      sev: case_details['severity'] + " : ",
      task_components: [...case_details['task_components']],
      skills: [...case_details['skill_matches']],
      skill_bonus: case_details['skill_full_bonus_v'],
      skill_bonus_percentage: case_details['skill_full_bonus_%'],
      ntse_bonus: case_details['ntse_bonus_v'],
      ntse_bonus_percentage: case_details['ntse_bonus_%'],
      atlas_penalty: case_details['atlas_penalty_v'],
      atlas_penalty_percentage: case_details['atlas_penalty_%'],
      score: case_details['tse_load_v'].toFixed(2),
      score_percentage: case_details['tse_load_%'],
      total_cost: case_details['total_cost'].toFixed(2),
    })
  };
};

export default connect(null, mapDispatchToProps)(CompareModel);
