import React from "react";
import "./App.css";
import NavBar from "./components/nav/navBar";
import { StitchAuthProvider, useStitchAuth } from "./stitch/StitchAuth.js";
import "./css/classicstyle.css";
import "react-dropdown/style.css";
import Login from "./auth/Login";
import LogTable from "./components/logs/logTable";
import ModelList from "./components/models/modelList";
import Analysis from "./components/analysisPage/runAnalysis";
import Model from "./components/modelComponent/ModelComp";
import EditModel from "./components/modelComponent/EditModel";
import ViewModel from "./components/modelComponent/ViewModel";
import CompareModel from "./components/modelComponent/CompareModel";
import Loadscore from "./components/loadscore/Loadscore";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { app } from "./stitch/app";

App.propTypes = {};
export default function App() {
  return (
    <StitchAuthProvider>
      <AppUI />
    </StitchAuthProvider>
  );
}

AppUI.propTypes = {};
function AppUI() {
  const { isLoggedIn } = useStitchAuth();
  let eztc_inspector_url = '';
  if (isLoggedIn) {
    console.log('isLoggedIn ' + isLoggedIn)
    const runID = Cookies.get("runID");
    const loadScoreID = Cookies.get("loadScoreID");
    const isLoadscore = Cookies.get("isLoadscore");
    const isHistory = Cookies.get("isHistory");
    console.log('runID ' + runID);
    console.log('loadScoreID ' + loadScoreID);
    if (app) {
      app.callFunction('getContextValue', ['eztc_inspector_url']).then(value => {
        console.log('value.url ' + value.url);
        eztc_inspector_url = value.url;
      });
    }
    console.log('eztc_inspector_url ' + eztc_inspector_url);
    if(runID) {
      window.location.href = eztc_inspector_url + `/history/${runID}`;
      Cookies.remove("runID");
      return 0;
    } else if (loadScoreID) {
      window.location.href = eztc_inspector_url + `/loadscore/${loadScoreID}`;
      Cookies.remove("loadScoreID");
      return 0;
    } else if (isHistory) {
      window.location.href = eztc_inspector_url + `/history`;
      Cookies.remove("isHistory");
      return 0;
    } else if (isLoadscore) {
      window.location.href = eztc_inspector_url + `/loadscore`;
      Cookies.remove("isLoadscore");
      return 0;
    }
    return (
      <Router>
        <React.Fragment>
          <NavBar />
          <Switch>
            <Route path="/" exact component={LogTable} />
            <Route path="/history" exact component={LogTable} />
            <Route path="/history/:id" exact component={Analysis} />
            <Route path="/models" component={ModelList} />
            <Route path="/create" exact component={Model} />
            <Route path="/edit/:id" exact component={EditModel} />
            <Route path="/view/:id" exact component={ViewModel} />
            <Route path="/compare/:id" exact component={CompareModel} />
            <Route path="/loadscore" exact component={Loadscore} />
            <Route path="/loadscore/:id" exact component={Loadscore} />
          </Switch>
        </React.Fragment>
      </Router>
    );
    }
   else {
    const queryString = window.location;
    console.log('queryString ' + queryString);
    const regExResultID = queryString.pathname.match(/\/(history|loadscore)\/([0-9A-Za-z@.]+)/);
    const regExResultPath = queryString.pathname.match(/\/(history|loadscore)/);
    console.log('regExResultID' + regExResultID);
    console.log('regExResultPath' + regExResultPath);
    if (regExResultID && include(regExResultID, 'history')) {
      let runID = queryString.pathname.split('/history/');
      console.log('runID ' + runID);
      if(runID) {
        window.location.href = window.location.href.replace(regExResultID[0], '');
        Cookies.set("runID", runID[1], { expires: 1});
        return <Login />;
      }
    } else if (regExResultID && include(regExResultID, 'loadscore')) {
      let loadScoreID = queryString.pathname.split('/loadscore/');
      console.log('loadScoreID ' + loadScoreID);
      if(loadScoreID) {
        window.location.href = window.location.href.replace(regExResultID[0], '');
        Cookies.set("loadScoreID", loadScoreID[1], { expires: 1});
        return <Login />;
      }
    } else if (regExResultPath && include(regExResultPath, 'history')) {
      window.location.href = window.location.href.replace(regExResultPath[0], '');
      Cookies.set("isHistory", true, { expires: 1});
      return <Login />;
    } else if (regExResultPath && include(regExResultPath, 'loadscore')) {
      window.location.href = window.location.href.replace(regExResultPath[0], '');
      Cookies.set("isLoadscore", true, { expires: 1});
      return <Login />;
    } else {
      return <Login />;
    }
  
}
  function include(regExResult, path) {
    console.log('include ' + regExResult + ' ' + path)
    return regExResult.findIndex((element) => element === path) === 1;
  }
}
