import React from 'react';
import styled from "@emotion/styled";
import { useStitchAuth } from "../../stitch/StitchAuth.js";
import { LogoMark } from '@leafygreen-ui/logo';
import { Link } from "react-router-dom";
import "./navBar.css";
// import { useState } from 'react';
// import { app } from '../../stitch/app';
// import Icon from '@leafygreen-ui/icon';
// import { Label } from "reactstrap";

export default function navBar() {
    return (
        <NavBar/>
    );
  }

  NavBar.propTypes = {};

  function NavBar() {
    const {
      isLoggedIn,
      actions: { handleLogout }
    } = useStitchAuth();

    // console.log(app.auth.user.profile.email);

        return (
        <Nav>
              <Link to="/" style={{ textDecoration: 'none' }}><AppTitle><LogoMark/><span className="appName">AutoTC</span></AppTitle></Link>
                <Menu>
                  <Link to="/history" style={{ textDecoration: 'none' }}><span className="runHistory">Run History</span></Link>
                  <Link to="/models" style={{ textDecoration: 'none' }}><span className="modelList">Models</span></Link>
                  <Link to="/loadscore" style={{ textDecoration: 'none' }}><span className="loadscore">Load Score</span></Link>
                </Menu>
                {isLoggedIn &&
                // <Link to="/"><span className="logout_user" onClick={handleLogout}>{`${userName}`}<Icon glyph="ChevronDown" className="nameOption"/></span></Link>
                <Link to="/"><span className="logout_label" onClick={handleLogout}>Logout</span></Link>
                }
                <hr className="navLine"/>
        </Nav>
        );
    }

const AppTitle = styled.div`
  padding-left: 7.5%;
  padding-top: 5px;
  display: inline;
`;

const Menu = styled.div`
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding-left: 12px;
`;

const Nav = styled.div`
  margin-top: 2px;
`;