import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loadscore from "../loadscore/Loadscore";
import "../../css/classicstyle.css";
import { connect } from "react-redux";
import { Subtitle } from "@leafygreen-ui/typography";

class CaseAnalysis extends Component {
  
  //Case Analysis Component with modifying values
  
  createCaseAnalysis = (caseTitle, caseTSE, infoTSE, caseSev, caseDescription, caseTask_components, 
    caseSkills, caseSkillBonus, caseSkillBonusPercentage, caseNTSEBonus, caseNTSEBonusPercentages,
    caseAtlasPenalty, caseAtlasPenaltyPercentage, tseCaseScore, caseScore_percentage, total_cost
    ) => {
    let table = [];
    let row_header = [];
    let row_info = [];
    let case_header = caseTitle + caseSev + caseTSE;

    // Loop to create Table Header

    row_header.push(
      <TableCell colSpan="3" id="case_details_title">
        {case_header}
      </TableCell>
    );
    table.push(<TableRow>{row_header}</TableRow>);

    // Loop to create Table Body

    row_info = [];
    // here's where that info will be used later
    console.log('infoTSE: '+caseTSE);
    console.log(infoTSE);
    row_info.push(
      <TableCell id="case_details" colSpan="3">
        CASE DESCRIPTION
        <p id="tse_case_info">{(caseDescription) ? caseDescription : "Case Description"}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style"></TableCell>);
    row_info.push(<TableCell id="case_row_style"></TableCell>);
    table.push(<TableRow>{row_info}</TableRow>);
      
    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        CASE COMPONENTS / MATCHING SKILLS
        <p id="tse_case_info">{(caseTask_components) ? caseTask_components + " / " + caseSkills : "Case Components Associated and Matching Skills of the TSE" }</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseSkillBonusPercentage) ? caseSkillBonusPercentage + "%": "0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(caseSkillBonus) ? "-" + caseSkillBonus : "0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        NTSE
        <p id="tse_case_info">{(caseNTSEBonus)? "Yes" : "Yes/No"}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseNTSEBonusPercentages)?caseNTSEBonusPercentages+ "%":"0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(caseNTSEBonus)?"-" + caseNTSEBonus:"0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        ATLAS - ENGINEER
        <p id="tse_case_info">{(caseAtlasPenalty) ? "Atlas TSE - Non Atlas Case" : '-'}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseAtlasPenaltyPercentage)?caseAtlasPenaltyPercentage+ "%":"0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(caseAtlasPenalty)?caseAtlasPenalty:"0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        TSE LOAD SCORE
        <p id="tse_case_info">{(caseTSE)? caseTSE : "Name of the TSE"}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseScore_percentage)?caseScore_percentage+ "%":"0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(tseCaseScore)?tseCaseScore:"0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell colSpan="3" id="case_details_title">
        {(total_cost)?"Total Score: " + total_cost :"Total Score: 0"}
      </TableCell>
    );
    table.push(<TableRow>{row_info}</TableRow>);
    return table;
  };

  render() {
    console.log('re-render '+this.props.caseTSE);
    return (
      <React.Fragment>
        <div className="case_analysis_div">
          <Subtitle className="case_analysis_heading">Cost Calculator</Subtitle>
          <TableContainer>
            <Table id="case_analysis">
              {this.createCaseAnalysis(
                this.props.caseNumberR,
                this.props.caseTSE,
                this.props.infoTSE,
                this.props.caseSev,
                this.props.caseDescription,
                this.props.caseTask_components,
                this.props.caseSkills,
                this.props.caseSkillBonus,
                this.props.caseSkillBonusPercentage,
                this.props.caseNTSEBonus,
                this.props.caseNTSEBonusPercentages,
                this.props.caseAtlasPenalty,
                this.props.caseAtlasPenaltyPercentage,
                this.props.tseCaseScore,
                this.props.caseScore_percentage,
                this.props.total_cost
              )}
            </Table>
          </TableContainer>
        </div>
        <Loadscore userId={this.props.caseTSE} data={this.props.infoTSE} hideNotFound={true} ></Loadscore>
      </React.Fragment>
    );
  }
}

// Update the Redux state to the Component

const mapStateToProps = (state) => {
  return {
    caseNumberR: state.caseNumber,
    caseTSE: state.tse,
    infoTSE: state.tse_info,
    caseSev: state.sev,
    caseDescription: state.description,
    caseTask_components: state.task_components,
    caseSkills: state.skills,
    caseSkillBonus: state.skill_bonus,
    caseSkillBonusPercentage: state.skill_bonus_percentage,
    caseNTSEBonus: state.ntse_bonus,
    caseNTSEBonusPercentages: state.ntse_bonus_percentage,
    caseAtlasPenalty: state.atlas_penalty,
    caseAtlasPenaltyPercentage: state.atlas_penalty_percentage,
    tseCaseScore: state.score,
    caseScore_percentage: state.score_percentage,
    total_cost: state.total_cost,
  };
};

export default connect(mapStateToProps)(CaseAnalysis);