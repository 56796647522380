import { Stitch, UserApiKeyCredential } from "mongodb-stitch-browser-sdk";

let app = undefined;

if (getLocalApp()) {
  app = instantiateApp(getLocalApp().appId);
  app.auth
    .loginWithCredential(
      new UserApiKeyCredential(process.env.REACT_APP_USER_KEY)
    )
    .then((result) => {
      console.log("logged in with apikey: " + result);
    });
} else if (getDeploymentApp()) {
  const app = instantiateApp(getDeploymentApp().APP_CONFIG.appId);
} else {
  console.error("missing config file");
}
export { app };

function instantiateApp(appId) {
  // TODO: Initialize the app client
  app = Stitch.hasAppClient(appId)
    ? Stitch.getAppClient(appId)
    : Stitch.initializeAppClient(appId);
  return app;
}

function getLocalApp() {
  console.log("getLocalApp");
  let config = undefined;
  try {
    config = require("../config.json");
  } catch (err) {
    console.log("No existing local dev app [../config.json].");
  }
  console.log("getLocalApp " + JSON.stringify(config));
  return config;
}

function getDeploymentApp() {
  console.log("getDeploymentApp");
  let config = undefined;
  try {
    config = require("./config.js");
  } catch (err) {
    console.log("No existing local dev app [./config.js].");
  }
  console.log("getDeploymentApp " + JSON.stringify(config));
  return config;
}

