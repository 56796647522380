import React from "react";
import styled from "@emotion/styled";
import { useStitchAuth } from "../stitch/StitchAuth";
import { Card, CardBody } from "reactstrap";
import Button from '@leafygreen-ui/button';
import { LogoMark } from '@leafygreen-ui/logo';
import { Link, BrowserRouter as Router } from "react-router-dom";

Login.propTypes = {};
export default function Login() {
  const { actions } = useStitchAuth();
  return (
    <React.Fragment>
    <Router>
    <Nav>
    <Link to="/" style={{ textDecoration: 'none' }}><AppTitle><LogoMark/><span className="appName">AutoTC</span></AppTitle></Link>
    </Nav>
    <Layout>
      <Displaycard>
        <CardBody>
          <ButtonRow>
            <Button
              provider="google"
              onClick={() => actions.handleLogin("google")}
            >
              Log In with Google
            </Button>
          </ButtonRow>
        </CardBody>
      </Displaycard>
    </Layout>
    </Router>
    </React.Fragment>
  );
}
const Layout = styled.div`
  background: #eeeeee;
  height: 100%;
  padding: 20px;
`;
const Displaycard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.div`
  margin-top: 2px;
`;

const AppTitle = styled.h2`
  padding-left: 7.5%;
  padding-top: 5px;
  display: inline;
  font-size: 26px;
`;