import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import "../../css/classicstyle.css";
// import { connect } from "react-redux";
import { Subtitle } from "@leafygreen-ui/typography";

class CompareAnalysis extends Component {

constructor(props) {
    super(props);
    this.state = {
        caseNumber: "",
        tse: "",
        description: "",
        sev: "",
        task_components: "",
        skills: "",
        skill_bonus: "",
        skill_bonus_percentage: "",
        ntse_bonus: "",
        ntse_bonus_percentage: "",
        atlas_penalty: "",
        atlas_penalty_percentage: "",
        score: "",
        score_percentage: "",
        total_cost: "",
    }
}

static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    return {
        caseNumber: nextProps.caseNumber,
        tse: nextProps.tse,
        description: nextProps.description,
        sev: nextProps.sev,
        task_components: nextProps.task_components,
        skills: nextProps.skills,
        skill_bonus: nextProps.skill_bonus,
        skill_bonus_percentage: nextProps.skill_bonus_percentage,
        ntse_bonus: nextProps.ntse_bonus,
        ntse_bonus_percentage: nextProps.ntse_bonus_percentage,
        atlas_penalty: nextProps.atlas_penalty,
        atlas_penalty_percentage: nextProps.atlas_penalty_percentage,
        score: nextProps.score,
        score_percentage: nextProps.score_percentage,
        total_cost: nextProps.total_cost,
    };
  }


  //Case Analysis Component with modifying values
  
  createCaseAnalysis = (case_data) => {

    const caseTitle = case_data.CaseNumber;
    const caseTSE = case_data.tse_name;
    const caseSev = case_data.severity;
    const caseDescription = case_data.case_subj;
    const caseTask_components = case_data.task_components;
    const caseSkills = case_data.skill_matches;
    const caseSkillBonus = case_data.skill_full_bonus_v;
    const caseSkillBonusPercentage = case_data['skill_full_bonus_%'];
    const caseNTSEBonus = case_data.ntse_bonus_v;
    const caseNTSEBonusPercentages = case_data['ntse_bonus_%'];
    const caseAtlasPenalty = case_data.atlas_penalty_v;
    const caseAtlasPenaltyPercentage = case_data['atlas_penalty_%'];
    const tseCaseScore = case_data.tse_load_v;
    const caseScore_percentage = case_data['tse_load_%'];
    const total_cost = case_data.total_cost;
    let table = [];
    let row_header = [];
    let row_info = [];
    let case_header = caseTitle + " : " + caseSev + " : " + caseTSE;

    // Loop to create Table Header

    row_header.push(
      <TableCell colSpan="3" id="case_details_title">
        {(caseTitle) ? case_header : null}
      </TableCell>
    );
    table.push(<TableRow>{row_header}</TableRow>);

    // Loop to create Table Body

    row_info = [];

    row_info.push(
      <TableCell id="case_details" colSpan="3">
        CASE DESCRIPTION
        <p id="tse_case_info">{(caseDescription) ? caseDescription : "Case Description"}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style"></TableCell>);
    row_info.push(<TableCell id="case_row_style"></TableCell>);
    table.push(<TableRow>{row_info}</TableRow>);
      
    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        CASE COMPONENTS / MATCHING SKILLS
        <p id="tse_case_info">{(caseTask_components) ? caseTask_components + " / " + caseSkills : "Case Components Associated and Matching Skills of the TSE" }</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseSkillBonusPercentage) ? caseSkillBonusPercentage + "%": "0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(caseSkillBonus) ? "-" + caseSkillBonus : "0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        NTSE
        <p id="tse_case_info">{(caseNTSEBonus)? "Yes" : "Yes/No"}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseNTSEBonusPercentages)?caseNTSEBonusPercentages+ "%":"0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(caseNTSEBonus)?"-" + caseNTSEBonus:"0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        ATLAS - ENGINEER
        <p id="tse_case_info">{(caseAtlasPenalty) ? "Atlas TSE - Non Atlas Case" : '-'}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseAtlasPenaltyPercentage)?caseAtlasPenaltyPercentage+ "%":"0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(caseAtlasPenalty)?caseAtlasPenalty:"0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell id="case_details">
        TSE LOAD SCORE
        <p id="tse_case_info">{(caseTSE)? caseTSE : "Name of the TSE"}</p>
      </TableCell>
    );
    row_info.push(<TableCell id="case_row_style">{(caseScore_percentage)?caseScore_percentage+ "%":"0%"}</TableCell>);
    row_info.push(<TableCell id="case_row_style">{(tseCaseScore)?tseCaseScore.toFixed(2):"0"}</TableCell>);

    table.push(<TableRow>{row_info}</TableRow>);

    row_info = [];
    row_info.push(
      <TableCell colSpan="3" id="case_details_title">
        {(total_cost)?"Total Score: " + total_cost.toFixed(2) :"Total Score: 0"}
      </TableCell>
    );
    table.push(<TableRow>{row_info}</TableRow>);
    return table;
  };

  render() {
    return (
      <React.Fragment>
        <Subtitle className="case_analysis_heading">Cost Calculator</Subtitle>
        <TableContainer>
          <Table id="case_analysis">
            {this.createCaseAnalysis(this.props.case_details)}
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  }
}

export default CompareAnalysis;