import React, { Component } from "react";
import { H2 } from "@leafygreen-ui/typography";
import Icon from "@leafygreen-ui/icon";
import { Label } from "reactstrap";
import { app } from "../../stitch/app";
import Select from "react-select";
import Grid from '@material-ui/core/Grid';
import "./Model.css";

class ViewModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      userEmail: null,
      model_name: null,
      rr_minutes_static: [{
        value: 15,
        label: 15
      }],
      rr_excluded_minutes_static: [{
        value: 30,
        label: 30
      }],
      
      is_cloned: true,
      cloner: null,
      cloned_model_name: null,

      // Input Values for the Model being fetched
      classifier_confidence: null,
      severity_modifier: null,
      skill_level_bonus: null,
      skill_match_bonus: null,
      ntse_bonus: null,
      atlas_penalty: null,
      tse_coefficient: null,
      tse_load_exponent: null,
      max_case_weight: null,
      reference_case_load: null,
      assignment_bonus: null,
      assignment_penalty: null,
      rr_bonus: null,
      rr_penalty: null,
      rr_minutes: null,
      rr_threshold: null,
      rr_excluded_total_minutes: null,

      sla_current_15_minute_threshold: null,
      sla_current_15_minute_timeout: null,
      sla_current_15_minute_max_attempts: null,
      sla_current_30_minute_threshold: null,
      sla_current_30_minute_timeout: null,
      sla_current_30_minute_max_attempts: null,
      sla_current_60_minute_threshold: null,
      sla_current_60_minute_timeout: null,
      sla_current_60_minute_max_attempts: null,
      sla_current_120_minute_threshold: null,
      sla_current_120_minute_timeout: null,
      sla_current_120_minute_max_attempts: null,
      sla_current_240_minute_threshold: null,
      sla_current_240_minute_timeout: null,
      sla_current_240_minute_max_attempts: null,
      sla_current_360_minute_threshold: null,
      sla_current_360_minute_timeout: null,
      sla_current_360_minute_max_attempts: null,

      //Current Active Model Values
      current: {
        classifier_confidence: null,
        severity_modifier: null,
        skill_level_bonus: null,
        skill_match_bonus: null,
        ntse_bonus: null,
        atlas_penalty: null,
        tse_coefficient: null,
        tse_load_exponent: null,
        max_case_weight: null,
        reference_case_load: null,
        assignment_bonus: null,
        assignment_penalty: null },

        current_SLA: {
          sla_15: {
              threshold: null,
              timeout: null,
              max_attempts: null,
          },
          sla_30: {
              threshold: null,
              timeout: null,
              max_attempts: null,
          },
          sla_60: {
              threshold: null,
              timeout: null,
              max_attempts: null,
          },
          sla_120: {
              threshold: null,
              timeout: null,
              max_attempts: null,
          },
          sla_240: {
              threshold: null,
              timeout: null,
              max_attempts: null,
          },
          sla_360: {
              threshold: null,
              timeout: null,
              max_attempts: null,
          }
      },


      //New model that needs to be cloned
      cloned_classifier_confidence: null,
      cloned_severity_modifier: null,
      cloned_skill_level_bonus: null,
      cloned_skill_match_bonus: null,
      cloned_ntse_bonus: null,
      cloned_atlas_penalty: null,
      cloned_tse_coefficient: null,
      cloned_tse_load_exponent: null,
      cloned_max_case_weight: null,
      cloned_reference_case_load: null,
      cloned_assignment_bonus: null,
      cloned_assignment_penalty: null,
      cloned_rr_bonus: null,
      cloned_rr_penalty: null,
      cloned_rr_minutes: null,
      cloned_rr_threshold: null,
      cloned_rr_excluded_total_minutes: null,
      cloned_15_minute_sla_threshold: null,
      cloned_15_minute_sla_timeout: null,
      cloned_15_minute_sla_max_attempts: null,
      cloned_30_minute_sla_threshold: null,
      cloned_30_minute_sla_timeout: null,
      cloned_30_minute_sla_max_attempts: null,
      cloned_60_minute_sla_threshold: null,
      cloned_60_minute_sla_timeout: null,
      cloned_60_minute_sla_max_attempts: null,
      cloned_120_minute_sla_threshold: null,
      cloned_120_minute_sla_timeout: null,
      cloned_120_minute_sla_max_attempts: null,
      cloned_240_minute_sla_threshold: null,
      cloned_240_minute_sla_timeout: null,
      cloned_240_minute_sla_max_attempts: null,
      cloned_360_minute_sla_threshold: null,
      cloned_360_minute_sla_timeout: null,
      cloned_360_minute_sla_max_attempts: null
    };
  }

  componentDidMount() {
    let model_id;
    if(this.props.id) {
      model_id = this.props.id;
    } else {
      model_id = this.props.match.params.id;
    }
    console.log(model_id);
    app.callFunction("fetchModelInformation", [model_id]).then(async (res) => {
        console.log(res);
      this.setState(prevState => ({
        cloner: res.user_info.email,
        current: {
          ...prevState.current,
          classifier_confidence: res.active_model.weights.classifier_confidence,
          severity_modifier: res.active_model.weights.severity_modifier,
          skill_level_bonus: res.active_model.weights.skill_level_bonus,
          skill_match_bonus: res.active_model.weights.skill_match_bonus,
          ntse_bonus: res.active_model.weights.ntse_bonus,
          atlas_penalty: res.active_model.weights.atlas_penalty,
          tse_coefficient: res.active_model.weights.tse_coeff,
          tse_load_exponent: res.active_model.weights.tse_load_exponent,
          max_case_weight: res.active_model.weights.max_case_weight,
          reference_case_load: res.active_model.weights.reference_case_load,
          assignment_bonus: res.active_model.weights.assignment_bonus,
          assignment_penalty: res.active_model.weights.assignment_penalty,
          rr_bonus: res.active_model.weights.rr_bonus,
          rr_penalty: res.active_model.weights.rr_penalty,
          rr_minutes: res.active_model.weights.rr_total_minutes[0],
          rr_threshold: res.active_model.weights.rr_threshold,
          rr_excluded_total_minutes: res.active_model.weights.rr_excluded_total_minutes[0]
        },
        current_SLA: {
            ...prevState.current_SLA,
            sla_15: {
                ...prevState.current_SLA.sla_15,
                threshold: res.active_model.assignments.sla_15.threshold,
                timeout: res.active_model.assignments.sla_15.timeout,
                max_attempts: res.active_model.assignments.sla_15.max_attempts,
            },
            sla_30: {
                ...prevState.current_SLA.sla_30,
                threshold: res.active_model.assignments.sla_30.threshold,
                timeout: res.active_model.assignments.sla_30.timeout,
                max_attempts: res.active_model.assignments.sla_30.max_attempts,
            },
            sla_60: {
                ...prevState.current_SLA.sla_60,
                threshold: res.active_model.assignments.sla_60.threshold,
                timeout: res.active_model.assignments.sla_60.timeout,
                max_attempts: res.active_model.assignments.sla_60.max_attempts,
            },
            sla_120: {
                ...prevState.current_SLA.sla_120,
                threshold: res.active_model.assignments.sla_120.threshold,
                timeout: res.active_model.assignments.sla_120.timeout,
                max_attempts: res.active_model.assignments.sla_120.max_attempts,
            },
            sla_240: {
                ...prevState.current_SLA.sla_240,
                threshold: res.active_model.assignments.sla_240.threshold,
                timeout: res.active_model.assignments.sla_240.timeout,
                max_attempts: res.active_model.assignments.sla_240.max_attempts,
            },
            sla_360: {
                ...prevState.current_SLA.sla_360,
                threshold: res.active_model.assignments.sla_360.threshold,
                timeout: res.active_model.assignments.sla_360.timeout,
                max_attempts: res.active_model.assignments.sla_360.max_attempts,
            }
        }
        }));
      await this.setState({
        model_name: res.model.model_name,
        userName: res.model.creator,
        classifier_confidence: res.model.weights.classifier_confidence,
        severity_modifier: res.model.weights.severity_modifier,
        skill_match_bonus: res.model.weights.skill_match_bonus,
        skill_level_bonus: res.model.weights.skill_level_bonus,
        ntse_bonus: res.model.weights.ntse_bonus,
        atlas_penalty: res.model.weights.atlas_penalty,
        tse_coefficient: res.model.weights.tse_coeff,
        tse_load_exponent: res.model.weights.tse_load_exponent,
        max_case_weight: res.model.weights.max_case_weight,
        reference_case_load: res.model.weights.reference_case_load,
        assignment_bonus: res.model.weights.assignment_bonus,
        assignment_penalty: res.model.weights.assignment_penalty,
        rr_bonus: res.model.weights.rr_bonus,
        rr_penalty: res.model.weights.rr_penalty,
        rr_minutes: res.model.weights.rr_total_minutes[0],
        rr_threshold: res.model.weights.rr_threshold,
        rr_excluded_total_minutes: res.model.weights.rr_excluded_total_minutes[0],

        sla_current_15_minute_threshold: res.model.assignments.sla_15.threshold,
        sla_current_15_minute_timeout: res.model.assignments.sla_15.timeout,
        sla_current_15_minute_max_attempts: res.model.assignments.sla_15.max_attempts,
        sla_current_30_minute_threshold: res.model.assignments.sla_30.threshold,
        sla_current_30_minute_timeout: res.model.assignments.sla_30.timeout,
        sla_current_30_minute_max_attempts: res.model.assignments.sla_30.max_attempts,
        sla_current_60_minute_threshold: res.model.assignments.sla_60.threshold,
        sla_current_60_minute_timeout: res.model.assignments.sla_60.timeout,
        sla_current_60_minute_max_attempts: res.model.assignments.sla_60.max_attempts,
        sla_current_120_minute_threshold: res.model.assignments.sla_120.threshold,
        sla_current_120_minute_timeout: res.model.assignments.sla_120.timeout,
        sla_current_120_minute_max_attempts: res.model.assignments.sla_120.max_attempts,
        sla_current_240_minute_threshold: res.model.assignments.sla_240.threshold,
        sla_current_240_minute_timeout: res.model.assignments.sla_240.timeout,
        sla_current_240_minute_max_attempts: res.model.assignments.sla_240.max_attempts,
        sla_current_360_minute_threshold: res.model.assignments.sla_360.threshold,
        sla_current_360_minute_timeout: res.model.assignments.sla_360.timeout,
        sla_current_360_minute_max_attempts: res.model.assignments.sla_360.max_attempts
      });
    });
  }


  // Redirecting back to the model list after successful execution
  backToModels = () => {
    window.location.href = "https://autotc-inspector.corp.mongodb.com/models";
  }


  // Resettting the style of the input fields once user clicks on it
  resetStyle = (id) => {
    document.getElementById(id).style.borderColor = null;
  };


  // Sending an alter to the user

  sendAlert = (field, value) => {
    alert(`Please check ${field} value. ${value} is not acceptable.`);
  };

  // Copying the existing model values to the cloned model

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
        is_cloned: true,
        cloned_classifier_confidence: this.state.classifier_confidence,
        cloned_severity_modifier: this.state.severity_modifier,
        cloned_skill_level_bonus: this.state.skill_level_bonus,
        cloned_skill_match_bonus: this.state.skill_match_bonus,
        cloned_ntse_bonus: this.state.ntse_bonus,
        cloned_atlas_penalty: this.state.atlas_penalty,
        cloned_tse_coefficient: this.state.tse_coefficient,
        cloned_tse_load_exponent: this.state.tse_coefficient,
        cloned_max_case_weight: this.state.max_case_weight,
        cloned_reference_case_load: this.state.reference_case_load,
        cloned_assignment_bonus: this.state.assignment_bonus,
        cloned_assignment_penalty: this.state.assignment_penalty,
        cloned_rr_bonus: this.state.rr_bonus,
        cloned_rr_penalty: this.state.rr_penalty,
        cloned_rr_minutes: this.state.rr_minutes,
        cloned_rr_threshold: this.state.rr_threshold,
        cloned_rr_excluded_total_minutes: this.state.rr_excluded_total_minutes,
        cloned_15_minute_sla_threshold: this.state.sla_current_15_minute_threshold,
        cloned_15_minute_sla_timeout: this.state.sla_current_15_minute_timeout,
        cloned_15_minute_sla_max_attempts: this.state.sla_current_15_minute_max_attempts,
        cloned_30_minute_sla_threshold: this.state.sla_current_30_minute_threshold,
        cloned_30_minute_sla_timeout: this.state.sla_current_30_minute_timeout,
        cloned_30_minute_sla_max_attempts: this.state.sla_current_60_minute_max_attempts,
        cloned_60_minute_sla_threshold: this.state.sla_current_60_minute_threshold,
        cloned_60_minute_sla_timeout: this.state.sla_current_60_minute_threshold,
        cloned_60_minute_sla_max_attempts: this.state.sla_current_60_minute_max_attempts,
        cloned_120_minute_sla_threshold: this.state.sla_current_120_minute_threshold,
        cloned_120_minute_sla_timeout: this.state.sla_current_120_minute_timeout,
        cloned_120_minute_sla_max_attempts: this.state.sla_current_120_minute_max_attempts,
        cloned_240_minute_sla_threshold: this.state.sla_current_240_minute_threshold,
        cloned_240_minute_sla_timeout: this.state.sla_current_240_minute_timeout,
        cloned_240_minute_sla_max_attempts: this.state.sla_current_240_minute_max_attempts,
        cloned_360_minute_sla_threshold: this.state.sla_current_360_minute_threshold,
        cloned_360_minute_sla_timeout: this.state.sla_current_360_minute_timeout,
        cloned_360_minute_sla_max_attempts: this.state.sla_current_360_minute_max_attempts,
      });

    document.getElementById("cloned_model").style.display = "inline-block";
  };


  // Saving the cloned models with expected values
  
  handleSave = async (event) => {
    event.preventDefault();
    const model_data = {
        is_cloned: true,
        cloned_model_name: this.state.cloned_model_name,
        userEmail: this.state.cloner,
        cloned_classifier_confidence: this.state.cloned_classifier_confidence,
        cloned_severity_modifier: this.state.cloned_severity_modifier,
        cloned_skill_level_bonus: this.state.cloned_skill_level_bonus,
        cloned_skill_match_bonus: this.state.cloned_skill_match_bonus,
        cloned_ntse_bonus: this.state.cloned_ntse_bonus,
        cloned_atlas_penalty: this.state.cloned_atlas_penalty,
        cloned_tse_coefficient: this.state.cloned_tse_coefficient,
        cloned_tse_load_exponent: this.state.cloned_tse_load_exponent,
        cloned_max_case_weight: this.state.cloned_max_case_weight,
        cloned_reference_case_load: this.state.cloned_reference_case_load,
        cloned_assignment_bonus: this.state.cloned_assignment_bonus,
        cloned_assignment_penalty: this.state.cloned_assignment_penalty,
        cloned_rr_bonus: this.state.cloned_rr_bonus,
        cloned_rr_penalty: this.state.cloned_rr_penalty,
        cloned_rr_minutes: this.state.cloned_rr_minutes,
        cloned_rr_threshold: this.state.cloned_rr_threshold,
        cloned_rr_excluded_total_minutes: this.state.cloned_rr_excluded_total_minutes,
        cloned_15_minute_sla_threshold: this.state.cloned_15_minute_sla_threshold,
        cloned_15_minute_sla_timeout: this.state.cloned_15_minute_sla_timeout,
        cloned_15_minute_sla_max_attempts: this.state.cloned_15_minute_sla_max_attempts,
        cloned_30_minute_sla_threshold: this.state.cloned_30_minute_sla_threshold,
        cloned_30_minute_sla_timeout: this.state.cloned_30_minute_sla_timeout,
        cloned_30_minute_sla_max_attempts: this.state.cloned_30_minute_sla_max_attempts,
        cloned_60_minute_sla_threshold: this.state.cloned_60_minute_sla_threshold,
        cloned_60_minute_sla_timeout: this.state.cloned_60_minute_sla_timeout,
        cloned_60_minute_sla_max_attempts: this.state.cloned_60_minute_sla_max_attempts,
        cloned_120_minute_sla_threshold: this.state.cloned_120_minute_sla_threshold,
        cloned_120_minute_sla_timeout: this.state.cloned_120_minute_sla_timeout,
        cloned_120_minute_sla_max_attempts: this.state.cloned_120_minute_sla_max_attempts,
        cloned_240_minute_sla_threshold: this.state.cloned_240_minute_sla_threshold,
        cloned_240_minute_sla_timeout: this.state.cloned_240_minute_sla_timeout,
        cloned_240_minute_sla_max_attempts: this.state.cloned_240_minute_sla_max_attempts,
        cloned_360_minute_sla_threshold: this.state.cloned_360_minute_sla_threshold,
        cloned_360_minute_sla_timeout: this.state.cloned_360_minute_sla_timeout,
        cloned_360_minute_sla_max_attempts: this.state.cloned_360_minute_sla_max_attempts
    };

    const created = await app.callFunction("createNewModel", [model_data]);
    console.log(model_data);
    if(created) {
        alert("Model has been created!");
    }
    document.getElementById("cloned_model").style.display = "none";
    this.backToModels();
  };

validate = (id, value) => {
    if(!isNaN(value)) {
        this.setState({[id]: value});
    }
}

  render() {
    const message = "Hi, I am a tooltip";
    return (
    <div>
      <H2 className="tab-heading">Model Details</H2>
      <div className="model_div_left">
      <form onSubmit={this.handleSubmit}>
            <div className="create_model_info">
            <div className="create_model_name">
                <input id="new_model_name" className="create_model" value={this.state.model_name} readOnly/>
            </div>
            <br></br>
            <Label className="creator_name">
                Creator: {this.state.userName}
            </Label>
            <input className="clone_model_button" type="submit" value="Clone this Model" />
            </div>
            <div className="create_model_paramters">
            <div className="parameters_set">
                    <div>
                    <Label className="group_label">Ticket Information</Label>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Classifier Confidence</Label><Icon glyph="InfoWithCircle" size="small" title={message.classifier_confidence} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="classifier_confidence" value={this.state.classifier_confidence} readOnly>
                                </input><br></br>
                                <Label className="default_values">Current: {this.state.current.classifier_confidence}</Label><br></br>
                            </Grid>
                            <Grid item xs={5}>
                                <Label className="ticket_parameters">Severity Modifier</Label><Icon glyph="InfoWithCircle" size="small" title={message.severity_modifier} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="severity_modifier" value={this.state.severity_modifier} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.severity_modifier}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Label className="group_label">TSE Information</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Skill Level Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.skill_level_bonus} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="skill_level_bonus" value={this.state.skill_level_bonus} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.skill_level_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Skills Match Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.skill_match_bonus} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="skill_match_bonus" value={this.state.skill_match_bonus} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.skill_match_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">NTSE Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.ntse_bonus} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="ntse_bonus" value={this.state.ntse_bonus} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.ntse_bonus}</Label><br></br>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Atlas Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.atlas_penalty} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="atlas_penalty" value={this.state.atlas_penalty} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.atlas_penalty}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">TSE Coefficient</Label><Icon glyph="InfoWithCircle" size="small" title={message.tse_coeff} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="tse_coefficient" value={this.state.tse_coefficient} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.tse_coefficient}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">TSE Load Exponent</Label><Icon glyph="InfoWithCircle" size="small" title={message.tse_load_exponent} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="tse_load_exponent" value={this.state.tse_load_exponent} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.tse_load_exponent}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                    <Label className="group_label">Case Assignment</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Assignment Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.assignment_bonus} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="assignment_bonus" value={this.state.assignment_bonus} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.assignment_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Assignment Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.assignment_penalty} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="assignment_penalty" value={this.state.assignment_penalty} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.assignment_penalty}</Label><br></br>
                            </Grid>
                        </Grid>
                        </div>
                        <div>
                        <Label className="group_label">Rapid Responder</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_bonus} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="rr_bonus" value={this.state.rr_bonus} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_penalty} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="rr_penalty" value={this.state.rr_penalty} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_penalty}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">RR Total Minutes</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_minutes} className="tooltip_info_icon"/><br></br>
                                <Select
                                    className="input_fields_select"
                                    id="rr_minutes"
                                    defaultValue={this.state.rr_minutes_static}
                                    placeholder="RR Minutes"
                                    options={this.state.rr_minutes_static}
                                    isDisabled={true}
                                /><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_minutes}</Label><br></br>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Threshold</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_threshold} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="rr_threshold" value={this.state.rr_threshold} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_threshold}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Excluded Minutes</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_excluded_total_minutes} className="tooltip_info_icon"/><br></br>
                                <Select
                                    className="input_fields_select"
                                    id="rr_excluded_total_minutes"
                                    placeholder="RR Excluded Minutes"
                                    defaultValue={this.state.rr_excluded_minutes_static}
                                    options={this.state.rr_excluded_minutes_static}
                                    isDisabled={true}
                                /><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_excluded_total_minutes}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                    <Label className="group_label">Others</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Max Case Weight</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_case_weight} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="max_case_weight" value={this.state.max_case_weight} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.max_case_weight}</Label><br></br>
                            </Grid>
                            <Grid item xs={5}>
                                <Label className="ticket_parameters">Reference Case Load</Label><Icon glyph="InfoWithCircle" size="small" title={message.reference_case_load} className="tooltip_info_icon"/><br></br>
                                <input className="input_fields" id="reference_case_load" value={this.state.reference_case_load} readOnly></input><br></br>
                                <Label className="default_values">Current: {this.state.current.reference_case_load}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="create_model_paramters">
            <div className="parameters_set">
            <Label className="group_label">15 Minutes SLA</Label>
            <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_15_minute_threshold" value={this.state.sla_current_15_minute_threshold} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_15_minute_timeout" value={this.state.sla_current_15_minute_timeout} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_15_minute_max_attempts" value={this.state.sla_current_15_minute_max_attempts} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">30 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_30_minute_threshold" value={this.state.sla_current_30_minute_threshold} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_30_minute_timeout" value={this.state.sla_current_30_minute_timeout} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_30_minute_max_attempts" value={this.state.sla_current_30_minute_max_attempts} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">60 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_60_minute_threshold" value={this.state.sla_current_60_minute_threshold} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_60_minute_timeout" value={this.state.sla_current_60_minute_timeout} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_60_minute_max_attempts" value={this.state.sla_current_30_minute_max_attempts} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">120 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_120_minute_threshold" value={this.state.sla_current_120_minute_threshold} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_120_minute_timeout" value={this.state.sla_current_120_minute_timeout} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_120_minute_max_attempts" value={this.state.sla_current_120_minute_max_attempts} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">240 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_240_minute_threshold" value={this.state.sla_current_240_minute_threshold} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_240_minute_timeout" value={this.state.sla_current_240_minute_timeout} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_240_minute_max_attempts" value={this.state.sla_current_240_minute_max_attempts} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">360 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_360_minute_threshold" value={this.state.sla_current_360_minute_threshold} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_360_minute_timeout" value={this.state.sla_current_360_minute_timeout} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input className="input_fields" id="sla_current_360_minute_max_attempts" value={this.state.sla_current_360_minute_max_attempts} readOnly></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        </div>
        </div>
        </form>
        </div>
        
        <div id="cloned_model" className="model_div_right">
        <form onSubmit={this.handleSave}>
        <div className="create_model_info">
          <div className="create_model_name">
          <input id="new_model_name" className="create_model" placeholder="Model Name" onChange={(e)=> {this.setState({cloned_model_name: e.target.value})}} required/>
          </div>
          <br></br>
          <Label className="creator_name">
          Creator: {this.state.cloner}
          </Label>
          <input className="save_button" type="submit" value="Save"/>
          </div>
          <div className="create_model_paramters">
          <div className="parameters_set">
                    <div>
                    <Label className="group_label">Ticket Information</Label>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Classifier Confidence</Label><Icon glyph="InfoWithCircle" size="small" title={message.classifier_confidence} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_classifier_confidence" value={this.state.cloned_classifier_confidence} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e) => {this.validate(e.target.id, e.target.value)}} required>
                                </input><br></br>
                                <Label className="default_values">Current: {this.state.current.classifier_confidence}</Label><br></br>
                            </Grid>
                            <Grid item xs={5}>
                                <Label className="ticket_parameters">Severity Modifier</Label><Icon glyph="InfoWithCircle" size="small" title={message.severity_modifier} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_severity_modifier" value={this.state.cloned_severity_modifier} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.severity_modifier}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Label className="group_label">TSE Information</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Skill Level Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.skill_level_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_skill_level_bonus" value={this.state.cloned_skill_level_bonus} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.skill_level_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Skills Match Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.skill_match_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_skill_match_bonus" value={this.state.cloned_skill_match_bonus} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.skill_match_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">NTSE Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.ntse_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_ntse_bonus" value={this.state.cloned_ntse_bonus} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.ntse_bonus}</Label><br></br>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Atlas Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.atlas_penalty} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_atlas_penalty" value={this.state.cloned_atlas_penalty} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.atlas_penalty}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">TSE Coefficient</Label><Icon glyph="InfoWithCircle" size="small" title={message.tse_coeff} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_tse_coefficient" value={this.state.cloned_tse_coefficient} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.tse_coefficient}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">TSE Load Exponent</Label><Icon glyph="InfoWithCircle" size="small" title={message.tse_load_exponent} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_tse_load_exponent" value={this.state.cloned_tse_load_exponent} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}}></input><br></br>
                                <Label className="default_values">Current: {this.state.current.tse_load_exponent}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                    <Label className="group_label">Case Assignment</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Assignment Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.assignment_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_assignment_bonus" value={this.state.cloned_assignment_bonus} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.assignment_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Assignment Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.assignment_penalty} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_assignment_penalty" value={this.state.cloned_assignment_penalty} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.assignment_penalty}</Label><br></br>
                            </Grid>
                        </Grid>
                        </div>
                        <div>
                        <Label className="group_label">Rapid Responder</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Bonus</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_bonus} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_rr_bonus" value={this.state.cloned_rr_bonus} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_bonus}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Penalty</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_penalty} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_rr_penalty" value={this.state.cloned_rr_penalty} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_penalty}</Label><br></br>
                            </Grid>
                            <Grid item xs={3}>
                                <Label className="ticket_parameters">RR Total Minutes</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_minutes} className="tooltip_info_icon"/><br></br>
                                <Select
                                    className="input_fields_select"
                                    id="cloned_rr_minutes"
                                    defaultValue={this.state.rr_minutes_static}
                                    placeholder="RR Minutes"
                                    onChange={(e) => {this.setState({rr_minutes: e.value})}}
                                    options={this.state.rr_minutes_static}
                                /><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_minutes}</Label><br></br>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Threshold</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_threshold} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_rr_threshold" value={this.state.cloned_rr_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_threshold}</Label><br></br>
                            </Grid>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">RR Excluded Minutes</Label><Icon glyph="InfoWithCircle" size="small" title={message.rr_excluded_total_minutes} className="tooltip_info_icon"/><br></br>
                                <Select
                                    className="input_fields_select"
                                    id="cloned_rr_excluded_total_minutes"
                                    placeholder="RR Excluded Minutes"
                                    defaultValue={this.state.rr_excluded_minutes_static}
                                    onChange={(e) => {this.setState({cloned_rr_excluded_total_minutes: e.value})}}
                                    options={this.state.rr_excluded_minutes_static}
                                    required
                                /><br></br>
                                <Label className="default_values">Current: {this.state.current.rr_excluded_total_minutes}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                    <Label className="group_label">Others</Label>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Label className="ticket_parameters">Max Case Weight</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_case_weight} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_max_case_weight" value={this.state.cloned_max_case_weight} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.max_case_weight}</Label><br></br>
                            </Grid>
                            <Grid item xs={5}>
                                <Label className="ticket_parameters">Reference Case Load</Label><Icon glyph="InfoWithCircle" size="small" title={message.reference_case_load} className="tooltip_info_icon"/><br></br>
                                <input type="number" className="input_fields" id="cloned_reference_case_load" value={this.state.cloned_reference_case_load} onFocus={ (e) => this.resetStyle(e.target.id) }
                                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                                <Label className="default_values">Current: {this.state.current.reference_case_load}</Label><br></br>
                            </Grid>
                        </Grid>
                    </div>
                </div>
          </div>
          <div className="create_model_paramters">
            <div className="parameters_set">
            <Label className="group_label">15 Minutes SLA</Label>
            <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_15_minute_sla_threshold" value={this.state.cloned_15_minute_sla_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_15_minute_sla_timeout" value={this.state.cloned_15_minute_sla_timeout} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_15_minute_sla_max_attempts" value={this.state.cloned_15_minute_sla_max_attempts} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_15.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">30 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_30_minute_sla_threshold" value={this.state.cloned_30_minute_sla_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_30_minute_sla_timeout" value={this.state.cloned_30_minute_sla_timeout} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_30_minute_sla_max_attempts" value={this.state.cloned_30_minute_sla_max_attempts} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_30.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">60 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_60_minute_sla_threshold" value={this.state.cloned_60_minute_sla_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_60_minute_sla_timeout" value={this.state.cloned_60_minute_sla_timeout} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_60_minute_sla_max_attempts" value={this.state.cloned_60_minute_sla_max_attempts} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_60.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">120 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_120_minute_sla_threshold" value={this.state.cloned_120_minute_sla_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_120_minute_sla_timeout" value={this.state.cloned_120_minute_sla_timeout} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_120_minute_sla_max_attempts" value={this.state.cloned_120_minute_sla_max_attempts} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_120.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">240 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_240_minute_sla_threshold" value={this.state.cloned_240_minute_sla_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_240_minute_sla_timeout" value={this.state.cloned_240_minute_sla_timeout} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_240_minute_sla_max_attempts" value={this.state.cloned_240_minute_sla_max_attempts} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_240.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        <br></br>
        <Label className="group_label">360 Minutes SLA</Label>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Threshold</Label><Icon glyph="InfoWithCircle" title={message.threshold} size="small" className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_360_minute_sla_threshold" value={this.state.cloned_360_minute_sla_threshold} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.threshold}</Label><br></br>
            </Grid>
            <Grid item xs={4}>
                <Label className="ticket_parameters">Timeout</Label><Icon glyph="InfoWithCircle" size="small" title={message.timeout} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_360_minute_sla_timeout" value={this.state.cloned_360_minute_sla_timeout} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.timeout}</Label><br></br>
            </Grid>
            <Grid item xs={3}>
                <Label className="ticket_parameters">Maximum Attempts</Label><Icon glyph="InfoWithCircle" size="small" title={message.max_attempts} className="tooltip_info_icon"/><br></br>
                <input type="number" className="input_fields" id="cloned_360_minute_sla_max_attempts" value={this.state.cloned_360_minute_sla_max_attempts} onFocus={ (e) => this.resetStyle(e.target.id) }
                onChange={(e)=> {this.validate(e.target.id, e.target.value)}} required></input><br></br>
                <Label className="default_values">Current: {this.state.current_SLA.sla_360.max_attempts}</Label><br></br>
            </Grid>
        </Grid>
        </div>
        </div>
      </form>
      </div>
    </div>
    );
  }
}

export default ViewModel;
