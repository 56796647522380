import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  hasLoggedInUser,
  loginGoogle,
  addAuthenticationListener,
  getCurrentUser,
  handleOAuthRedirects,
  removeAuthenticationListener,
  logoutCurrentUser
} from "./auth";

//handleLogin initiate the authentication process with Google

export const handleLogin = () => {
  return loginGoogle();
};

const StitchAuthContext = React.createContext();

export function useStitchAuth() {
  const context = React.useContext(StitchAuthContext);
  if (!context) {
    throw new Error(`useStitchAuth must be used within a StitchAuthProvider`);
  }
  return context;
}

export function StitchAuthProvider(props) {
  const [authState, setAuthState] = React.useState({
    isLoggedIn: hasLoggedInUser(),
    currentUser: getCurrentUser()
  }, []);

  useEffect(() => {
    const authListener = {
      onUserLoggedIn: (auth, loggedInUser) => {
        if (loggedInUser) {
          setAuthState(authState => ({
            ...authState,
            isLoggedIn: true,
            currentUser: loggedInUser
          }));
        }
      },
      onUserLoggedOut: (auth, loggedOutUser) => {
        setAuthState(authState => ({
          ...authState,
          isLoggedIn: false,
          currentUser: null
        }));
      }
    };
    addAuthenticationListener(authListener);
    handleOAuthRedirects();
    setAuthState(state => ({ ...state }));
    return () => {
      removeAuthenticationListener(authListener);
    };
  }, []);

  //handleLogout takes care of the logout process and logs out the user form the currrent session
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogout = async () => {
    const { isLoggedIn } = authState;
    if (isLoggedIn) {
      await logoutCurrentUser();
      setAuthState({
        ...authState,
        isLoggedIn: false,
        currentUser: null
      });
    } else {
      console.log(`can't handleLogout when no user is logged in`);
    }
  };

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    const { isLoggedIn, currentUser } = authState;
    const value = {
      isLoggedIn,
      currentUser,
      actions: { handleLogin, handleLogout }
    };
    return value;
  }, [authState, handleLogout]);
  return (
    <StitchAuthContext.Provider value={authInfo}>
      {props.children}
    </StitchAuthContext.Provider>
  );
}

StitchAuthProvider.propTypes = {
  children: PropTypes.element
};
