import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Icon from "@leafygreen-ui/icon";
import { H2, Subtitle } from "@leafygreen-ui/typography";
import Button from "@leafygreen-ui/button";
import { Label } from "reactstrap";
import { app } from "../../stitch/app";
import React, { Component } from "react";
import Checkbox from "@leafygreen-ui/checkbox";
// import ModelSearchBar from "../searchItems/modelSearch";
import { Link } from "react-router-dom";
import "./modelList.css";
import "../searchItems/modelSearch.css";

class modelTable extends Component {
  state = {
    loadValue: 1,
    runData: [],
    default_data: [],
    federationIdentifier: app.auth.user.profile.email,
    activation_id: null
  };

  getMoreModels = async () => {
    const currentLoad = this.state.loadValue;
    await this.setState({ loadValue: currentLoad + 1 });
    this.postLogs();
    let data = [];
    await app.callFunction("getModelsList").then((res) => {
      res.forEach((elem) => {
        data.push(elem);
      });
      this.setState({ runData: data });
    });
  };

  searchModel = (model_name) => {
    this.state.default_data.forEach((model) => {
      if (model.model_name === model_name) {
        this.setState({ runData: [model] });
      } else if (model_name === "") {
        this.setState({ runData: this.state.default_data });
      } else {
        this.setState({ runData: [] });
      }
    });
  };

  displayMyModels = (checked) => {
    if (checked) {
      let myModels = [];
      this.state.default_data.forEach((model) => {
        if (model.creator === this.state.federationIdentifier) {
          myModels.push(model);
        }
      });
      this.setState({ runData: myModels });
      document.getElementById("search_model").value = "";
    } else {
      this.setState({ runData: this.state.default_data });
      document.getElementById("search_model").value = "";
    }
  };

  componentDidMount() {
    let data = [];
    const currentLoad = this.state.loadValue;
    app.callFunction("getModelsList", [currentLoad]).then((res) => {
      res.forEach((elem) => {
        data.push(elem);
      });
      this.setState({
        runData: data,
        default_data: data,
      });
    });
    var popup = document.getElementById("deleteModelPopUp");
    // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
          if (event.target === popup) {
            popup.style.display = "none";
      }
    }
  }

  activateModel = async (id) => {
    var popup = document.getElementById("deleteModelPopUp");
    popup.style.display = "block";
    await this.setState({activation_id: id});
  };

  backToModels = () => {
    window.location.href = "https://autotc-inspector.corp.mongodb.com/models";
  };

  confirmedActivate = async () => {
    const success = await app
      .callFunction("activateModel", [this.state.activation_id])
      .then((res) => {
        return res;
      });
    console.log("Activating Model" + success);
    if (success) {
      this.backToModels();
    } else {
      alert("Error in model activation!");
      this.backToModels();
    }
  }

  postLogs = () => {
    return (
      <React.Fragment>
        <H2 className="tab-heading">AutoTC Models</H2>
        {/* <ModelSearchBar /> */}
        <div className="searchModel">
          <input
            id="search_model"
            type="search"
            placeholder="Search an AutoTC model"
            onChange={(e) => {
              this.searchModel(e.target.value);
            }}
          ></input>
          <Checkbox
            className="myModels"
            onChange={(e) => {
              this.displayMyModels(e.target.checked);
            }}
            label="Display only my models"
            animate={true}
            bold={false}
          />
          <Link to="/create">
            <Button type="button" className="createModel">
              Create New Model
            </Button>
          </Link>
        </div>
        <Subtitle className="activeModelheading">Active Model</Subtitle>
        <Table
          data={this.state.default_data}
          className="activeModelTable"
          columns={[
            <TableHeader className="activeModel" />,
            <TableHeader className="activeModel" />,
            <TableHeader className="activeModel" />,
          ]}
        >
          {({ datum }) => (
            <Row key={datum.model_name} className="activeModelRow">
              <Cell className="custom">
                {datum.is_active_hub && datum.is_runnable ? (
                  <Link to={`/view/${datum._id}`} className="activeModelName">
                    {datum.model_name}
                  </Link>
                ) : null}
              </Cell>
              <Cell className="custom">
                {datum.is_active_hub && datum.is_runnable
                  ? datum.creator
                  : null}
              </Cell>
              <Cell>
                {datum.is_active_hub && datum.is_runnable
                  ? datum.created
                  : null}
              </Cell>
            </Row>
          )}
        </Table>
        <Subtitle className="allModelheading">All Models</Subtitle>
        <Table
          data={this.state.runData}
          className="metrics-table"
          columns={[
            <TableHeader
              label="Name"
              sortBy={(datum) => datum.model_name}
              className="table-header"
            />,
            <TableHeader label="Creator" />,
            <TableHeader label="Date" sortBy={(datum) => datum.created} />,
            <TableHeader label="Action" />,
          ]}
        >
          {({ datum }) => (
            <Row key={datum.model_name}>
              <Cell className="custom">
                <Link to={`/view/${datum._id}`} className="modelNames">
                  {datum.model_name}
                </Link>
              </Cell>
              <Cell className="custom">{datum.creator}</Cell>
              <Cell>{datum.created}</Cell>
              <Cell className="currentActiveModel">
                {datum.is_active_hub && datum.is_runnable ? (
                  <Label className="temp">Active</Label>
                ) : this.state.federationIdentifier === datum.creator ? (
                  <React.Fragment>
                    <button
                      id={datum._id}
                      className="compareButton"
                      type="button"
                      onClick={(e) => this.activateModel(e.target.id)}
                    >
                      Make Active
                    </button>
                    <Link to={`/edit/${datum._id}`}>
                      <Icon glyph="Edit" size="large" className="editIcon" />
                    </Link>
                  </React.Fragment>
                ) : (
                  <button
                    id={datum._id}
                    className="compareButton"
                    type="button"
                    onClick={(e) => this.activateModel(e.target.id)}
                  >
                    Make Active
                  </button>
                )}
              </Cell>
            </Row>
          )}
        </Table>
        {this.state.runData.length >= 20 ? (
          <div className="loadMore">
            <Button
              className="loadMoreButton"
              onClick={() => this.getMoreModels()}
            >
              Load More
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div id="deleteModelPopUp" className="deleteModelPopUp">
          <div className="deleteModalContent">
            <Icon
              glyph="X"
              size="large"
              className="closeIcon"
              onClick={() => {
                document.getElementById("deleteModelPopUp").style.display =
                  "none";
              }}
            ></Icon>
            <p>Are you sure you want to activate this model?</p>
            <div className="delete_actions">
              <input
                className="cancel_popup"
                type="submit"
                value="Cancel"
                onClick={() => {
                  document.getElementById("deleteModelPopUp").style.display =
                    "none";
                }}
              />
              <input
                id="activation_id"
                className="confirm_delete"
                type="submit"
                value="Yes"
                onClick={(e) => {
                  this.confirmedActivate();
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {
    return this.postLogs();
  }
}

export default modelTable;
