const analyzeCase = {
    caseNumber: "",
    tse: "",
    description: "",
    sev: "",
    task_components: "",
    skills: "",
    skill_bonus: "",
    skill_bonus_percentage: "",
    ntse_bonus: "",
    ntse_bonus_percentage: "",
    atlas_penalty: "",
    atlas_penalty_percentage: "",
    score: "",
    score_percentage: "",
    total_cost: "",
}

//Updating the new states for the CaseAnanlysis Componenet

const caseToAnalyze = (state = analyzeCase, action) => {
    switch(action.type){
        case 'UPDATE':
            return {
                caseNumber: action.newCase,
                tse: action.tse,
                tse_info: action.tse_info,
                description: action.description,
                sev: action.sev,
                task_components: action.task_components,
                skills: action.skills,
                skill_bonus: action.skill_bonus,
                skill_bonus_percentage: action.skill_bonus_percentage,
                ntse_bonus: action.ntse_bonus,
                ntse_bonus_percentage: action.ntse_bonus_percentage,
                atlas_penalty: action.atlas_penalty,
                atlas_penalty_percentage: action.atlas_penalty_percentage, 
                score: action.score,
                score_percentage: action.score_percentage,
                total_cost: action.total_cost,
            };
        default:
            return state;
    }
}

export default caseToAnalyze;