import React from 'react';
import moment from 'moment';
import Badge from "@leafygreen-ui/badge";

type Props = {
    isSLA: boolean,
    isFollowUp: boolean,
    color: any,
    expiresAt: Date,
}

type State = {
    display: string,
    color: any,
    initialized: boolean
}

const MINUTE: number = 0;
const HOUR: number = 1;
const DAY: number = 2;

class Countdown extends React.Component<Props, State> {

    state = { display: '', color: undefined, initialized: false };

    interval: any = undefined;
    selectedInterval: any;
    expiresAt: any;
    display = '';
    isSLA = false;
    isFollowUp = false;
    start: any;
    days = 0;
    hours = 0;
    minutes = 0;
    seconds = 0;
    color = '';

    initialize = () => {

        if (!this.props.expiresAt) {
            this.setState({ display: 'N/A', color: 'red', initialized: true });
        } else {

            this.expiresAt = moment(this.props.expiresAt);

            let startdown = moment.duration(this.expiresAt.diff(moment()));

            this.days = Math.floor(startdown.days());
            this.hours = Math.floor(startdown.hours());
            this.minutes = Math.floor(startdown.minutes());
            this.seconds = Math.floor(startdown.seconds());

            if (this.seconds <= 0 && this.minutes <= 0 && this.hours <= 0 && this.days <= 0) {
                this.setState({ display: 'MISSED', color: 'red', initialized: true });
            } else {

                this.color = this.props.color;
                this.isSLA = this.props.isSLA;
                this.isFollowUp = this.props.isFollowUp

                if (this.days > 0) {
                    this.display = `${this.days}D ${this.hours}H`;
                } else if (this.hours > 0) {
                    this.display = `${this.hours}H ${this.minutes}M`;
                    // this.display = `${this.hours}H ${this.minutes}M ${this.seconds}S`;
                } else if (this.seconds >= 0) {
                    this.display = `${this.minutes}M ${this.seconds}S`;
                }

                this.start = moment();
                this.start.add({ days: this.days, hours: this.hours, minutes: this.minutes, seconds: this.seconds });

                this.setState({
                    display: this.display,
                    color: this.props.color,
                    initialized: true
                });

                if (this.days > 0) {
                    this.selectedInterval = this.getIntervalType()[DAY];
                } else if (this.hours > 0) {
                    this.selectedInterval = this.getIntervalType()[HOUR];
                } else {
                    this.selectedInterval = this.getIntervalType()[MINUTE];
                }

                this.startInterval();
            }
        }
    }

    componentDidMount() {

        this.initialize();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            console.log('clear interval');
        }
    }

    componentDidUpdate(prevProps: any) {

        if (this.props.expiresAt != null &&
            prevProps?.expiresAt != null &&
            moment(this.props.expiresAt).isSame(moment(prevProps?.expiresAt))) {

            // do nothing for real

        } else {

            if (this.props.expiresAt) { 

                this.start = moment(this.props.expiresAt);

                if (!this.interval) {
                    this.selectedInterval = this.getIntervalType()[MINUTE];
                    this.startInterval();
                }
            } 
        }
    }

    startInterval() {

        this.interval = setInterval(() => {
            if (Number.isNaN(this.seconds) || (this.seconds <= 0 && this.minutes <= 0 && this.hours <= 0 && this.days <= 0)) {
                clearInterval(this.interval);
                this.setState({ display: 'MISSED', color: 'red', initialized: true });
            } else {

                let countdown = moment.duration(moment(this.start).diff(moment()));

                this.days = Math.floor(countdown.days());
                this.hours = Math.floor(countdown.hours());
                this.minutes = Math.floor(countdown.minutes());
                this.seconds = Math.floor(countdown.seconds());

                let format = '';

                if (this.days > 0) {
                    format = `${this.days}D ${this.hours}H`;
                    if (this.selectedInterval.key !== DAY) {
                        this.selectedInterval = this.getIntervalType()[DAY];
                        this.changeInterval();
                    }
                } else if (this.hours > 0) {
                    format = `${this.hours}H ${this.minutes}M`;
                    //format = `${this.hours}H ${this.minutes}M ${this.seconds}S`;
                    if (this.selectedInterval.key !== HOUR) {
                        this.selectedInterval = this.getIntervalType()[HOUR];
                        this.changeInterval();
                    }
                } else {
                    format = `${this.minutes}M ${this.seconds}S`
                    if (this.selectedInterval.key !== MINUTE) {
                        this.selectedInterval = this.getIntervalType()[MINUTE];
                        this.changeInterval();
                    }
                }

                let color = "lightgray";

                if (((this.isSLA && (this.days === 0 && this.hours < 1))) || (this.isFollowUp && (this.days === 0 && this.hours < 1))) {
                    color = "red";
                } else if ((this.isSLA && (this.days === 0 && this.hours < 2)) || (this.isFollowUp && (this.days === 0 && this.hours < 2))) {
                    color = "yellow";
                } else if ((this.isSLA && (this.days === 0 && this.hours < 4)) || (this.isFollowUp && (this.days === 0 && this.hours < 4))) {
                    color = "green";
                } else if ((this.isSLA && (this.days === 0 && this.hours < 8)) || (this.isFollowUp && (this.days === 0 && this.hours < 8))) {
                    color = "green";
                }

                this.setState({
                    display: format,
                    color: color
                });
            }
        }, this.selectedInterval.milliseconds);
    }

    changeInterval() {
        clearInterval(this.interval);
        this.startInterval();
    }

    getIntervalType(): { [key: number]: any } {
        return {
            0: {
                key: MINUTE,
                milliseconds: 1000  // every second
            },
            1: {
                key: HOUR,
                milliseconds: 1000 * 1 // every 30 seconds
            },
            2: {
                key: DAY,
                milliseconds: 1000 * 60  // every 60 second
            }
        }
    };

    render() {
        const { display, initialized } = this.state;
        return (
            initialized && <Badge className={"sla-badge badge-" + this.state.color} variant={this.state.color}>{display} </Badge>
        );
    }
}

export default Countdown;