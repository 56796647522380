import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import { H2 } from "@leafygreen-ui/typography";
import Button from '@leafygreen-ui/button';
import { app } from "../../stitch/app";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import "../searchItems/datestyle.css";
import "../searchItems/searchItems.css";
import "../logs/logTable.css";

class LogTable extends Component {
  state = {
    loadValue: 1,
    runData: [],
    startDate: null,
    endDate: null
  };

  getMoreLogs = async () => {
    const currentLoad = this.state.loadValue;
    await this.setState({ loadValue: currentLoad + 1 });
    this.postLogs();
    let data = [];
    await app
      .callFunction("getWeightsModifierData", [this.state.loadValue])
      .then((res) => {
        res.forEach((elem) => {
          data.push(elem);
        });
        this.setState({ runData: data });
      });
  };

  componentDidMount() {
    let data = [];
    const currentLoad = this.state.loadValue;
    app.callFunction("getWeightsModifierData", [currentLoad]).then((res) => {
      res.forEach((elem) => {
        data.push(elem);
      });
      this.setState({ runData: data });
    });
  }


  setStartDate = async (date) => {
    await this.setState({startDate: date});
  }

  setEndDate = async (date) => {
    await this.setState({endDate: date});
    console.log("Start: " + this.state.startDate + "\nEnd: " + this.state.endDate);
    if(this.state.startDate && this.state.endDate){
      const start = new Date(this.state.startDate);
      const end = new Date(this.state.endDate);
      await app.callFunction("searchByDate", [start, end]).then( async (res) => {
        await this.setState({runData: res});
      });
      document.getElementById("load_more_logs").style.display = "none";
    } else if (this.state.startDate === "" && this.state.endDate === "") {
        await this.setState({loadValue: 0,
        runDate: []});
        this.getMoreLogs();
        document.getElementById("load_more_logs").style.display = "flex";
    }
  }

  searchById = async (runId) => {
    if(runId) {
    await app.callFunction("searchLogs", [runId]).then(res => {
      this.setState({runData: res});
      document.getElementById("load_more_logs").style.display = "none"
    });
  } else {
    await this.setState({loadValue: 0,
    runDate: []});
    this.getMoreLogs();
    document.getElementById("load_more_logs").style.display = "flex";
  }
  }

  postLogs = () => {
    return (
      <React.Fragment>
        <H2 className="tab-heading">AutoTC Run History</H2>
        <div className="searchComponents">
      <input
        id="search_logs"
        type="search"
        placeholder="Search an AutoTC instance"
        onBlur={(e) => {this.searchById(e.target.value)}}
      ></input>
      <label className="dateLabel">Start Date:</label>
      <Datetime
        id="start_date"
        className="searchDate"
        dateFormat="MM/DD/YYYY"
        timeFormat="HH:mm"
        onClose={this.setStartDate}
        inputProps={{ placeholder: "Choose a Start Date and Time", className: "inside-div" }}
      />
      <label>End Date:</label>
      <Datetime
        id="end_date"
        className="searchDate"
        dateFormat="MM/DD/YYYY"
        timeFormat="HH:mm"
        onClose={this.setEndDate}
        inputProps={{ placeholder: "Choose an end Date and Time", className: "inside-div" }}
      />
    </div>
        <Table
          data={this.state.runData}
          className="metrics-table"
          columns={[
            <TableHeader label="Run ID" className="table-header" />,
            <TableHeader label="Cases Analysed" />,
            <TableHeader label="Models Ran" />,
            <TableHeader label="Date" sortBy={(datum) => datum.date} />,
            <TableHeader label="Time (UTC)" sortBy={(datum) => datum.time} />,
            <TableHeader label="Action" />,
          ]}
        >
          {({ datum }) => (
            <Row key={datum._id}>
              <Cell className="custom">
                  {
                  (datum.cases_analysed) ? <Link className="run_id_link" to={`/history/${datum._id}`}>{"" + datum._id}</Link> : ("" + datum._id)
                  }
              </Cell>
              <Cell>{"" + datum.cases_analysed}</Cell>
              <Cell>{datum.solutionsNum}</Cell>
              <Cell>{datum.created_date}</Cell>
              <Cell>{datum.time}</Cell>
              <Cell>
                {(datum.cases_analysed === 0 || datum.solutionsNum <= 1) ? (
                  <Button className="disabledButton" type="button" disabled>
                    Compare
                  </Button>
                ) : (
                  <Link className="compare_link" to={`/compare/${datum._id}`}><Button className="compareButton">Compare</Button></Link>
                )}
              </Cell>
            </Row>
          )}
        </Table>
        <div id="load_more_logs" className="loadMore">
          <Button className="loadMoreButton" onClick={() => this.getMoreLogs()}>
            Load More
          </Button>
        </div>
      </React.Fragment>
    );
  };
  render() {
    return this.postLogs();
  }
}

export default LogTable;
