import React from "react";

const TableFooter = () => {
  return (
    <div className="labelContainer">
      <div className="tableLabel">
        <svg width="50" height="20">
          <g>
            <rect
              x="0"
              y="0"
              width="30"
              height="20"
              className="selectedSLA"
            ></rect>
            <text
              x="15"
              y="10"
              dominant-baseline="middle"
              text-anchor="middle"
              className="slaText"
            >
              x
            </text>
          </g>
        </svg>
        <div className="tableLabel">SLA</div>
      </div>
      <div className="tableLabel">
        <svg width="50" height="20">
          <g>
            <rect width="30" height="20" className="selectedLabel"></rect>
            <text
              x="15"
              y="10"
              dominant-baseline="middle"
              text-anchor="middle"
              className="slaText"
            >
              x
            </text>
          </g>
        </svg>
        <div className="tableLabel">Selected</div>
      </div>
      <div className="tableLabel">
        <svg width="50" height="20">
          <g>
            <rect width="30" height="20" className="selectedFTS"></rect>
            <text
              x="15"
              y="10"
              dominant-baseline="middle"
              text-anchor="middle"
              className="slaText"
            >
              x
            </text>
          </g>
        </svg>
        <div className="tableLabel">FTS</div>
      </div>
      <div className="tableLabel">
        <svg width="50" height="20">
          <g>
            <rect width="30" height="20" className="selectedUNA"></rect>
            <text
              x="15"
              y="10"
              dominant-baseline="middle"
              text-anchor="middle"
              className="slaText"
            >
              x
            </text>
          </g>
        </svg>
        <div className="tableLabel">UNA</div>
      </div>
    </div>
  );
};

export const CaseLabel = () => {
  return <div className="caseLabel">Cases Needing Assignment</div>;
};

export const TSELabel = () => {
  return <div className="tseLabel">TSEs / Load Scores</div>;
};

export default TableFooter;